import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

const ConfirmationModalContent = ({
  id = "",
  handleCancel = () => {},
  handleConfirm = () => {},
  title = <></>,
  isLoading = false,
  customClass = "",
}) => {
  return (
    <div
      className={`px-5 py-3 d-flex flex-column justify-content-center ${
        customClass ? customClass : ""
      }`}>
      <p
        className="fs-5 fw-normal"
        style={{
          color: "#263b5e",
        }}>
        {title}
      </p>

      <Row md={12} className="user-card-button justify-content-between">
        <Col className="col-content col-sm-0 flex-md-grow-1">
          <div
            className="action-button-outline w-100 mb-sm-0"
            onClick={handleCancel}>
            <div className=" px-0 flex-grow-1 m-0">No</div>
          </div>
        </Col>
        <Col className=" col-content col-sm-0 flex-md-grow-1">
          <button
            onClick={() => {
              handleConfirm(id, handleCancel);
            }}
            type="button"
            className="action-button mb-sm-0 w-100 d-flex justify-content-center">
            {isLoading ? (
              <div>
                <Spinner
                  animation="border"
                  size="sm"
                  className="text-white"
                  style={{ width: "1rem" }}
                />
              </div>
            ) : (
              <div className=" px-0 flex-grow-1 m-0">Yes</div>
            )}
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmationModalContent;
