import React, { useState } from "react";

import FilterNav from "components/FilterNav/FilterNav";
import Submenu from "components/SubMenu/Submenu";
import BasicInfoPR from "components/PermissionRequest/BasicInfoPR";

const PermissionRequest = () => {
  const [basicActive, setBasicActive] = useState("Basic Info");

  const tabs = [
    {
      title: "Basic Info",
      content: <BasicInfoPR />,
    },
    {
      title: "Security",
      content: "This is the content of Security",
    },
    {
      title: "Notifications",
      content: "This is the content of Notifications",
    },
    {
      title: "Users",
      content: "This is the content of Users",
    },
    {
      title: "Users Permissions",
      content: "This is the content of Users Permissions",
    },
  ];

  return (
    <>
      <h4>Reports Permission</h4>
      <Submenu
        tabs={tabs}
        FilterNav={<FilterNav />}
        basicActive={basicActive}
        setBasicActive={setBasicActive}
      />
    </>
  );
};

export default PermissionRequest;
