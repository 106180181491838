import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";

import "./AllReports.scss";

import StarFillSvg from "../../assets/svg/star-fill.svg";
import StarOutlineSvg from "../../assets/svg/star-outline.svg";
import WhiteArrowSvg from "../../assets/svg/white-arrow.svg";
import CustomToolTip from "components/ToolTip";
import ModalBox from "components/Modal/ModalBox";
import ConfirmationBox from "components/ConfirmationModalContent/ConfirmationModalContent";

const MiniCard = (props) => {
  const {
    id,
    title,
    color,
    isDescription = true,
    description,
    handleNavigation,
    container,
    handleDeleteReport,
    isDeletable = false,
    showDeleteLoader = false,
    isDisabled = false,
  } = props;

  const closeBtnRef = useRef(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hoverCard, setHoverCard] = useState(false);

  const handleShowConfirmModal = () => {
    setShowConfirmModal((current) => !current);
  };

  const handleOnMouseMove = () => {
    if (isDeletable) {
      setHoverCard(true);
    } else {
      setHoverCard(false);
    }
  };

  useEffect(() => {
    if (closeBtnRef?.current) {
      closeBtnRef.current.style.setProperty(
        "background",
        "#EAF3FE",
        "important"
      );
    }
  });

  return (
    <>
      <div
        className={"h-100 position-relative"}
        // onClick={() => handleActiveTab("Custom Reports")}
        onMouseMove={() => {
          if (!isDisabled) {
            handleOnMouseMove();
          }
        }}
        onMouseLeave={() => {
          if (hoverCard && !isDisabled) {
            setHoverCard(false);
          }
        }}>
        {hoverCard && (
          <button
            className="btn btn-sm text-danger position-absolute btn-light shadow-none m-0 p-0 rounded-circle z-5 fw-bold"
            ref={closeBtnRef}
            style={{
              top: "-10px",
              right: "-13px",
            }}
            onClick={handleShowConfirmModal}>
            <IoClose className="fs-2 shadow-lg" />
          </button>
        )}
        <div
          className={"cursor-pointer mini-card justify-content-between h-100 "}
          style={{
            "--card-color": isDisabled ? "#cccccc" : color,
            cursor: isDisabled ? "not-allowed" : "pointer",
          }}
          onClick={(e) => {
            if (!isDisabled) {
              handleNavigation(e);
            } else {
              return;
            }
          }}>
          <div className="d-flex h-100">
            <img
              src={container === "TopUsed" ? StarFillSvg : StarOutlineSvg}
              alt="star"
              className="star-fill mt-3"
            />
            <div className="d-flex flex-column card-content">
              <h4>{title}</h4>
              {isDescription && (
                <CustomToolTip text={description} placement={"bottom"}>
                  <p className="overflow-elipsis mb-0">{description}</p>
                </CustomToolTip>
              )}
            </div>
          </div>
          <img
            src={WhiteArrowSvg}
            alt="arrow"
            className={`white-arrow align-self-center ${
              description ? "white-arrow-center" : "white-arrow-right"
            }`}
          />
        </div>
      </div>
      <ModalBox
        customClass="special-modal"
        show={showConfirmModal}
        onHide={handleShowConfirmModal}
        content={
          <ConfirmationBox
            handleCancel={handleShowConfirmModal}
            handleConfirm={handleDeleteReport}
            id={id}
            title={
              <span>
                Are you sure want to delete <em>{title}</em> report ?
              </span>
            }
            isLoading={showDeleteLoader}
          />
        }
        size="md"
      />
    </>
  );
};

export default MiniCard;
