import { useCallback, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

import { UserContext } from "context/UserContext";
import { FilterContext } from "context/FilterContext";
import {
  getClientsList,
  getEmployeeList,
  getIndustryList,
  getPositionRoles,
} from "services/FetchApiData";

import DefaultLayout from "layout/DefaultLayout";
import Dashboard from "pages/Dashboard/Dashboard";
import Users from "pages/Users/Users";
import Reports from "pages/Reports/Reports";
import PerformanceReport from "pages/PerformanceReport/PerformanceReport";
import PermissionRequest from "pages/PermissionRequest/PermissionRequest";
import GenericTableView from "pages/GenericTableView/GenericTableView";
import PerformanceTableReport from "pages/PerformanceTableReport/PerformanceTableReport";
import Settings from "pages/Settings/Settings";
import {
  PipelineStageChoices,
  PipelineStageText,
  haveLeaderboardAccess,
} from "helpers/Constant";
import PipelineReport from "pages/PipelineReport/PipelineReport";
import Leaderboard from "pages/Leaderboard/Leaderboard";
import { selectOptionConverter } from "helpers/ReusableFunctions";
import CandidateDirectAccess from "pages/CandidateDirectAccess/CandidateDirectAccess";
import JobList from "pages/JobList/JobList";
import CandidateList from "pages/CandidateList/CandidateList";

const MainContent = () => {
  const { fetchUserData, language, userGroup, isUserLoading } =
    useContext(UserContext);
  const {
    filterStore,
    setDropdownData,
    isInternalUser,
    setYourIndustryId,
    setFilterStore,
    setLoading,
  } = useContext(FilterContext);

  const [positionData, setPositionData] = useState([]);

  const location = useLocation();

  const fetchIndustriesOptions = async () => {
    let defaultIndustry = "";
    try {
      setLoading(true);
      const industryResponse = await getIndustryList();
      setDropdownData((prevDropdownData) => ({
        ...prevDropdownData,
        IndustryOption:
          industryResponse.data.data?.industry_filters?.industries,
      }));
      defaultIndustry =
        industryResponse.data.data?.industry_filters?.your_industry_id;
      if (defaultIndustry) {
        setFilterStore((prevFilterStore) => ({
          ...prevFilterStore,
          Industry: selectOptionConverter([defaultIndustry])[0],
        }));
        setYourIndustryId(defaultIndustry);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeOptions = async () => {
    try {
      setLoading(true);
      const response = await getEmployeeList();
      const businessManagerOptions = response?.data?.data?.employee_filters
        .filter((emp) => emp?.role >= 30)
        .map((emp) => ({
          value: emp?.id,
          label: emp?.first_name + " " + emp?.last_name,
        }));
      const candidateManagerOptions = response?.data?.data?.employee_filters
        .filter((emp) => emp?.role >= 20)
        .map((emp) => ({
          value: emp?.id,
          label: emp?.first_name + " " + emp?.last_name,
        }));

      setDropdownData((prevDropdownData) => ({
        ...prevDropdownData,
        BusinessManagerOptions: businessManagerOptions,
        CandidateManagerOptions: candidateManagerOptions,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setStageManagerOptions = useCallback(() => {
    let stageManagerOptions = Object.keys(PipelineStageChoices)?.map((key) => ({
      value: PipelineStageChoices[key],
      label: PipelineStageText[PipelineStageChoices[key]],
    }));

    if (location.pathname === "/recentlyactivecandidatesreport") {
      stageManagerOptions.push({
        label: "New Candidate",
        value: "new_candidate",
      });
    } else {
      if (Object.keys(stageManagerOptions).includes("new_candidate")) {
        stageManagerOptions = stageManagerOptions.filter(
          (stage) => stage.value !== "new_candidate"
        );
      }
    }

    if (!isInternalUser) {
      stageManagerOptions = stageManagerOptions.filter(
        (opt) => opt.value !== "pitching"
      );
    }

    setDropdownData((prevDropdownData) => ({
      ...prevDropdownData,
      StageManagersOptions: stageManagerOptions,
    }));
  }, [isInternalUser, location.pathname, setDropdownData]);

  const fetchPositionsOptions = async () => {
    try {
      setLoading(true);
      const response = await getPositionRoles();
      const newPositions = response?.data?.data?.position_role_filters?.map(
        (value) => value.id
      );
      setPositionData(response.data.data.position_role_filters);
      setDropdownData((prevDropdownData) => ({
        ...prevDropdownData,
        PositionsOption: newPositions,
      }));
    } catch (error) {
      console.warn("User session not found");
    } finally {
      setLoading(false);
    }
  };

  const fetchClientsOptions = async () => {
    try {
      setLoading(true);
      const res = await getClientsList(language);
      const resToOptions = res.data.data.client_filters.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setDropdownData((prevDropdownData) => ({
        ...prevDropdownData,
        ClientOptions: resToOptions,
        ClientManagerOptions: resToOptions,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const setFocusDropdownData = useCallback(() => {
    const speciality_options = !isEmpty(filterStore.Positions)
      ? positionData.find(
          (focusValue) => focusValue?.id === filterStore?.Positions?.value
        )?.focuses ?? []
      : [];

    setDropdownData((prevDropdownData) => ({
      ...prevDropdownData,
      SpecialityOption: [...speciality_options],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore.Positions, positionData]);

  useEffect(() => {
    fetchUserData(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    fetchPositionsOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFocusDropdownData();
  }, [setFocusDropdownData]);

  useEffect(() => {
    if (userGroup.includes("internal")) {
      fetchClientsOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup, language]);

  useEffect(() => {
    fetchIndustriesOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userGroup.includes("internal")) {
      fetchEmployeeOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup]);

  useEffect(() => {
    setStageManagerOptions();
  }, [setStageManagerOptions]);

  return (
    <DefaultLayout>
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="/users" element={<Users />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/performancereport" element={<PerformanceReport />} />
        <Route path="/activepipelinereport" element={<PipelineReport />} />
        <Route path="/timetohirereport" element={<PipelineReport />} />
        <Route path="/successkpireport" element={<PipelineReport />} />
        {isInternalUser && (
          <>
            <Route
              path="/candidatepipelinereport"
              element={<PipelineReport />}
            />
            <Route
              path="/recentlyactivecandidatesreport"
              element={<PipelineReport />}
            />
            <Route
              path="/candidates/:candidate_id/card"
              element={<CandidateDirectAccess />}
            />
            {location.state?.leaderboard_access_ids &&
              haveLeaderboardAccess.map((reportPath, i) => (
                <Route
                  key={`${i}_${reportPath}`}
                  path={`/${reportPath}/leaderboard`}
                  element={<Leaderboard />}
                />
              ))}
          </>
        )}
        <Route path="/jobs" element={<JobList />} />
        <Route path="/candidates" element={<CandidateList />} />
        <Route path="/generictableview" element={<GenericTableView />} />
        <Route
          path="/performancetablereport"
          element={<PerformanceTableReport />}
        />
        <Route path="/permission" element={<PermissionRequest />} />
        <Route path="/settings" element={<Settings />} />
        {!isUserLoading && (
          <Route path="/*" element={<Navigate to="/" replace={true} />} />
        )}
      </Routes>
    </DefaultLayout>
  );
};

export default MainContent;
