import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { AiOutlineCopy } from "react-icons/ai";
import { IoCheckmarkSharp } from "react-icons/io5";

import "./CopyBtn.scss";
import CustomToolTip from "components/ToolTip";

const CopyBtn = ({ text = "" }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  const copyQuestion = async () => {
    try {
      setIsCopying(true);
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsCopying(false);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  return isCopying ? (
    <Spinner
      animation="border"
      size="sm"
      className="p-1 border-1"
      style={{
        color: "#6754e2",
      }}
    />
  ) : isCopied ? (
    <IoCheckmarkSharp
      style={{
        color: "#6754e2",
      }}
    />
  ) : (
    <CustomToolTip placement={"top"} text="Copy question">
      <button
        className="btn btn-secondary bg-transparent p-0 border-0"
        onClick={copyQuestion}>
        <AiOutlineCopy className="copy-icon" />
      </button>
    </CustomToolTip>
  );
};

export default CopyBtn;
