import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Amplify, Hub } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import awsConfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";

import "./App.scss";

import { UserContext } from "context/UserContext";
import MainContent from "MainContent";

import bannerShapeCorner from "assets/svg/banner-shape-corner.svg";
import bannerShapeTop from "assets/svg/banner-shape-top.svg";
import shapeDot from "assets/svg/shape-dot.svg";
import BannerSvg from "assets/svg/banner.svg";

function App() {
  const { User } = useContext(UserContext);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  const [amplifyConfigured, setAmplifyConfigured] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!amplifyConfigured) {
      Amplify.configure(awsConfig);
      setAmplifyConfigured(true);
    }
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      if (event === "signIn_failure") {
        setError(
          data
            .toString()
            .replace(/\+/g, " ")
            .replace("Error: PreSignUp failed with error ", "")
        );
      }
    });
    return unsubscribe;
  }, [amplifyConfigured]);

  useEffect(() => {
    if (error && authStatus === "authenticated") {
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStatus]);

  return (
    <Row className="g-0">
      <div className="App">
        <div className="main-content">
          <div className="banner-shape-top banner-shape">
            <img src={bannerShapeTop} alt="banner-shape-top" />
          </div>
          <div className="banner-shape-corner banner-shape">
            <img src={bannerShapeCorner} alt="banner-shape-corner" />
          </div>
          <div className="shape-dot banner-shape">
            <img src={shapeDot} alt="banner-shape-corner" />
          </div>

          <Col
            xs={12}
            sm={12}
            md={User?.username ? 12 : 7}
            lg={User?.username ? 12 : 7}
            className="align-self-center">
            <Authenticator hideSignUp loginMechanisms={["email"]}>
              <MainContent />
            </Authenticator>
          </Col>
          {!User?.username && (
            <Col md={4} lg={4} className="align-self-center">
              <div className="banner-img">
                <img
                  src={BannerSvg}
                  alt="banner-img"
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
            </Col>
          )}
        </div>
      </div>
    </Row>
  );
}

export default App;
