import React, { useContext, useEffect, useState } from "react";

import SelectUI from "components/Select/SelectUI";
import { FilterContext } from "context/FilterContext";
import {
  findStageCodeByKey,
  selectOptionConverter,
} from "helpers/ReusableFunctions";
import { PipelineStageChoices } from "helpers/Constant";

import "./style.scss";

const StagesSelector = ({ resetReport = () => {}, isDisabled = false }) => {
  const { dropdownData, filterStore, setFilterStore } =
    useContext(FilterContext);

  const removedStageOptionsForTimeToHire = [2, 90, 91, 92, 93, 94].map(
    (val) => PipelineStageChoices[val]
  );

  const stagesOptions = dropdownData.StageManagersOptions?.filter(
    (option) => !removedStageOptionsForTimeToHire.includes(option.value)
  );

  const [stage2Options, setStage2Options] = useState(stagesOptions ?? []);

  const getEndStageOptionsBasedStartStage = (stage1Value) => {
    if (!stage1Value?.value) {
      return stagesOptions;
    }
    return stagesOptions.filter(
      (option) =>
        stage1Value?.value !== option.value &&
        +findStageCodeByKey(option.value) >
          +findStageCodeByKey(stage1Value.value)
    );
  };

  const handleStage1Change = () => {
    if (filterStore.StageManagers2?.value) {
      setStage2Options(stagesOptions);
      setFilterStore((prevFilterStore) => ({
        ...prevFilterStore,
        StageManagers2: [],
      }));
      resetReport();
    }
  };

  const setStageManager1 = (e, entryName) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      [entryName]: e,
    }));
    handleStage1Change();
  };
  const setStageManager2 = (e, entryName) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      [entryName]: e,
    }));
  };

  useEffect(() => {
    if (filterStore?.StageManagers1?.value) {
      setStage2Options(() => {
        return getEndStageOptionsBasedStartStage(filterStore.StageManagers1);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore.StageManagers1]);

  return (
    <>
      <SelectUI
        options={stagesOptions}
        entryName={"StageManagers1"}
        placeholder={"Select Stage"}
        selectedValue={setStageManager1}
        className="w-100 border-radius-tr-br-0"
        value={filterStore.StageManagers1}
        isDisabled={isDisabled}
      />
      <SelectUI
        options={selectOptionConverter(stage2Options)}
        entryName={"StageManagers2"}
        placeholder={"Select Stage"}
        selectedValue={setStageManager2}
        className="w-100 border-radius-tl-bl-0"
        value={filterStore.StageManagers2}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default StagesSelector;
