import React from "react";
import "./Square.scss";

const Square = ({ value, color }) => {
  return (
    <div className="square-container" style={{ backgroundColor: color }}>
      <span>{value}</span>
    </div>
  );
};

export default Square;
