import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { FilterContext } from "context/FilterContext";
import "../../pages/Users/Users.scss";
import SelectUI from "../Select/SelectUI";
import {
  isValidEmail,
  selectOptionConverter,
} from "../../helpers/ReusableFunctions";

const AddUserCard = ({ userData, setUserData, setShowModal }) => {
  const roleOption = ["Super Admin", "Recruiter", "Client"];
  const { dropdownData } = useContext(FilterContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    userRole: "",
    industry: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "email" && !isValidEmail(value)) {
      setErrors((prev) => ({ ...prev, email: "Invalid email address" }));
    } else if (name === "email" && isValidEmail(value)) {
      setErrors((prev) => {
        delete prev.email;
        return prev;
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = {};

    // Perform form validation
    if (!formData.firstName.trim()) {
      newErrors.firstName = "Required";
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Required";
    } else if (!isValidEmail(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Required";
    } else if (!isValidPhoneNumber(formData.phone)) {
      newErrors.phone = "Invalid phone number";
    }

    if (!formData.industry) {
      newErrors.industry = "Required";
    }
    if (!formData.userRole) {
      newErrors.userRole = "Required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const newUser = {
      id: userData.length + 1,
      user: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      phone: formData.phone,
      role: formData.userRole,
      industry: formData.industry,
      status: "Active",
    };
    setUserData((prevUserData) => [...prevUserData, newUser]);

    // Reset the form
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      industry: "",
      userRole: "",
    });
    setShowModal(false);
    setErrors({});
  };

  const isValidPhoneNumber = (phone) => {
    // Implement phone number validation logic
    // Return true if the phone number is valid, false otherwise
    return true;
  };

  const selectedUserRole = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      userRole: e.value,
    }));
  };

  const selectedIndustry = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      industry: e.label,
    }));
  };

  return (
    <div className="add-user-card">
      <form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <div className="user-label">
              First Name{" "}
              {errors.firstName && (
                <span className="error">{errors.firstName}</span>
              )}
            </div>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />

            <br />
          </Col>
          <Col md={6}>
            <div className="user-label">
              Last Name{" "}
              {errors.lastName && (
                <span className="error">{errors.lastName}</span>
              )}
            </div>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />

            <br />
          </Col>
          <Col md={6}>
            <div className="user-label">
              SMS Number{" "}
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
            />

            <br />
          </Col>
          <Col md={6}>
            <div className="user-label">
              Email{" "}
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />

            <br />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="user-label">
              Industry{" "}
              {errors.industry && (
                <span className="error">{errors.industry}</span>
              )}
            </div>
            <SelectUI
              options={selectOptionConverter(dropdownData.IndustryOption)}
              className="add-class-select"
              selectedValue={selectedIndustry}
            />

            <br />
          </Col>
          <Col md={6}>
            <div className="user-label">
              User Role{" "}
              {errors.userRole && (
                <span className="error">{errors.userRole}</span>
              )}
            </div>
            <SelectUI
              options={selectOptionConverter(roleOption)}
              className="add-class-select"
              selectedValue={selectedUserRole}
            />

            <br />
          </Col>
          <Col md={12} className="user-card-button">
            <button type="submit" className="action-button mb-4">
              <div className="action-text">Save</div>
            </button>
            <div
              className="action-button-outline"
              onClick={() => setShowModal(false)}>
              <div className="action-text">Cancel</div>
            </div>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default AddUserCard;
