import { useState, useEffect, useContext } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "./loader.scss";
import "react-circular-progressbar/dist/styles.css";

import { FilterContext } from "context/FilterContext";
import { loaderMessages } from "helpers/Constant";

const Loader = () => {
  const { loading } = useContext(FilterContext);
  const [textIndex, setTextIndex] = useState(0);
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prev) => prev + 1);
      setPercentage((prev) => {
        if (prev < 100) {
          return prev + (loading ? 1 : 20);
        } else {
          return prev;
        }
      });
    }, [200]);

    return () => {
      clearInterval(interval);
      setTextIndex(0);
      setPercentage(0);
    };
  }, [loading]);

  return (
    <>
      {percentage < 100 && (
        <div className="loader-container">
          <div style={{ width: 140, height: 140 }}>
            <CircularProgressbar
              value={percentage}
              text={`<ESAI.WORK>`}
              maxValue={100}
              // strokeWidth={10}
              styles={{
                path: { stroke: "#6754e2" },
                trail: { stroke: "rgb(234, 234, 234)" },
                text: { fill: "#4433ae", fontSize: "11px", fontWeight: "bold" },
              }}
            />
          </div>

          <p style={{ color: "#4433ae", fontSize: "20px" }}>
            {loaderMessages[textIndex % loaderMessages.length]}
          </p>
        </div>
      )}
    </>
  );
};

export default Loader;
