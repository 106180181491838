import React, { memo, useEffect, useMemo, useState } from "react";
import {
  useGridSelector,
  gridFilteredSortedRowEntriesSelector,
} from "@mui/x-data-grid-pro";
import "./JobDetailsModalContent.scss";

import JobDetailsModalHeader from "./JobDetailsModalHeader";
import JobDetailsModalBody from "./JobDetailsModalBody";

const JobDetailsModalContent = ({
  current = {},
  title = "",
  apiRef = null,
  closeModal = () => {},
}) => {
  const rows = useGridSelector(apiRef, gridFilteredSortedRowEntriesSelector);
  const reportsData = useMemo(() => rows?.map((el) => el.model), [rows]);

  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState();
  const [currentData, setCurrentData] = useState(current);

  useEffect(() => {
    setCurrentIndex(
      reportsData?.findIndex((el) => el?.position_id === current?.position_id)
    );
  }, [reportsData, current?.position_id]);

  useEffect(() => {
    const data = reportsData[currentIndex];
    setCurrentData(data);
    // eslint-disable-next-line
  }, [currentIndex]);

  return (
    <div>
      <JobDetailsModalHeader
        title={title}
        currentData={currentData}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        reportsData={reportsData}
        closeModal={closeModal}
        loading={loading}
      />
      <JobDetailsModalBody
        currentData={currentData}
        setCurrentData={setCurrentData}
        loading={loading}
        setLoading={setLoading}
      />
    </div>
  );
};

export default memo(JobDetailsModalContent);
