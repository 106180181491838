import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import AccordionHeader from "react-bootstrap/esm/AccordionHeader";
import AccordionItem from "react-bootstrap/esm/AccordionItem";

const AccordionComp = ({
  id = "",
  eventKey = 1,
  handleToggle = () => {},
  accordionBody = <></>,
  minimizeText = "Minimize",
  expandText = "Expand",
  loading = "",
  isClientSide = true,
}) => {
  const [activeKeys, setActiveKeys] = useState([]);

  const isExpanded = (eKey) => {
    const index = activeKeys.indexOf(eKey);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleAccordionToggle = (eKey, id) => {
    const index = activeKeys.indexOf(eKey);
    if (index > -1) {
      activeKeys.splice(index, 1);
      setActiveKeys([...activeKeys]);
    } else {
      setActiveKeys(activeKeys.concat(eKey));
      handleToggle(id);
    }
  };

  return (
    <Accordion className="p-0" activeKey={activeKeys}>
      <AccordionItem eventKey={eventKey} style={{ border: "0px" }}>
        <AccordionHeader
          onClick={() => {
            handleAccordionToggle(eventKey, id);
          }}>
          {isExpanded(eventKey) ? minimizeText : expandText}
        </AccordionHeader>
        <AccordionBody className="px-2 py-0">
          {!isClientSide ? (
            loading ? (
              <p className="p-1 text-muted">Loading....</p>
            ) : (
              accordionBody
            )
          ) : (
            accordionBody
          )}
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default AccordionComp;
