import { TextField } from "@mui/material";
import React, { useState } from "react";

const CustomFilter = (props) => {
  const { item, applyValue, focusElementRef } = props;

  const [inputValue, setInputValue] = useState(item?.value || "");

  const filterInputRef = React.useRef(null);
  React.useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      filterInputRef.current
        .querySelector(`input[value="${item?.value ?? ""}"]`)
        ?.focus();
    },
  }));

  const apply = (value) => {
    applyValue({ ...item, value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      apply(event.target.value);
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      id="standard-basic"
      label="Value"
      placeholder="Filter Value"
      variant="standard"
      type="text"
      value={inputValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputLabelProps={{ shrink: true }}
      ref={filterInputRef}
      {...props}
    />
  );
};

export default CustomFilter;
