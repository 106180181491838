import React, { forwardRef, useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineDeleteOutline, MdOutlineEdit } from "react-icons/md";

import { formatDate } from "helpers/ReusableFunctions";
import ModalBox from "components/Modal/ModalBox";
import ConfirmationModalContent from "components/ConfirmationModalContent/ConfirmationModalContent";
import { deleteNote } from "services/FetchApiData";
import { UserContext } from "context/UserContext";

const NoteHistoryDetails = forwardRef((props, ref) => {
  const {
    notesDetail = [],
    noteSelected = {},
    setNoteSelected = () => {},
    setNotes = () => {},
  } = props;
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const [noteBeingDeleted, setNoteBeingDeleted] = useState({});

  const { loggedInUserData } = useContext(UserContext);

  const handleSelectNote = (note) => {
    setNoteBeingDeleted({});
    if (noteSelected.id === note.id) {
      setNoteSelected({});
    } else {
      setNoteSelected(note);
    }
  };

  const handleDeleteNote = async (id) => {
    setNoteSelected({});
    try {
      setShowDeleteLoader(true);
      const res = await deleteNote(id);
      setNotes((prevNotes) => {
        return prevNotes.filter(
          (note) => note.id !== res.data.data.candidates.delete_cm_note
        );
      });
    } catch (error) {
      console.error(error);
    } finally {
      setShowDeleteLoader(false);
      setNoteBeingDeleted({});
    }
  };

  const handleShowConfirmModal = (note) => {
    setNoteBeingDeleted({});
  };

  return (
    <>
      <p className="profile_subsection-title mb-0">Note History</p>
      <div className="card mt-3 mb-4 p-0 w-100 border-0" ref={ref}>
        {notesDetail
          .sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
          .map((note, index, arr) => (
            <Row
              key={note.id}
              className={`gap-2 py-4 w-100 m-0  ${
                note.id === noteSelected.id ? "note-selected" : "note-hover"
              }               
              ${arr.length - 1 !== index ? "border-bottom" : ""} `}>
              <Row className="m-0 w-100 justify-content-start">
                <Col md={12} className="text-truncate">
                  <p className="profile_subsection-subtitle mb-0">
                    {note?.title}
                  </p>
                </Col>
              </Row>
              <Row className="m-0 w-100 gap-2 justify-content-between">
                <Col sm={12} md={8}>
                  <p
                    className="profile_subsection-body mb-0 overflow-y-auto scroller"
                    style={{
                      whiteSpace: "pre",
                      maxHeight: "180px",
                    }}>
                    {note?.content}
                  </p>
                </Col>
                <Col
                  md={3}
                  className="d-flex flex-column align-items-end justify-content-start ">
                  <p className="profile_subsection-subtitle mb-0 text-nowrap">
                    {note?.author?.first_name + " " + note?.author?.last_name}
                  </p>
                  <p className="profile_subsection-body mb-0">
                    {formatDate(note?.created_at)}
                  </p>
                  {(loggedInUserData?.email === note?.author?.email ||
                    loggedInUserData?.is_admin) && (
                    <div className="d-flex gap-2 mt-3">
                      <MdOutlineEdit
                        fill={
                          noteSelected?.id === note?.id ? "#485166" : "#727fa2"
                        }
                        className="note-icon icon-hover"
                        onClick={() => handleSelectNote(note)}
                      />
                      <MdOutlineDeleteOutline
                        fill={
                          noteBeingDeleted?.id === note?.id
                            ? "#485166"
                            : "#727fa2"
                        }
                        className="note-icon icon-hover"
                        onClick={() => setNoteBeingDeleted(note)}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Row>
          ))}
      </div>
      <ModalBox
        show={!!noteBeingDeleted?.id}
        onHide={handleShowConfirmModal}
        customStyle={{
          backgroundColor: "rgba(0,0,0,0.2)",
        }}
        content={
          <ConfirmationModalContent
            handleCancel={handleShowConfirmModal}
            handleConfirm={handleDeleteNote}
            id={noteBeingDeleted.id}
            title={
              <span>
                Are you sure want to delete note{" "}
                <i>{noteBeingDeleted?.title}</i> ?
              </span>
            }
            isLoading={showDeleteLoader}
          />
        }
        size="md"
      />
    </>
  );
});

export default NoteHistoryDetails;
