import React from "react";
import "./CircleLabel.scss";

const Circlebar = ({ data, colors }) => {
  return (
    <div className="d-flex gap-4 justify-content-center flex-wrap">
      {data?.map((entry, index) => {
        const capitalizedEntryName =
          entry.name?.charAt(0).toUpperCase() + entry.name?.slice(1);
        return (
          <div
            className="d-flex justify-content-center align-items-center gap-2"
            key={index}
          >
            <div
              className="circle"
              style={{ backgroundColor: colors[index] }}
            ></div>
            {capitalizedEntryName}
          </div>
        );
      })}
    </div>
  );
};

export default Circlebar;
