import { Col, Row } from "react-bootstrap";

import {
  formatDate,
  convertStageCodeToName,
  getThreeMonthsOldDate,
} from "helpers/ReusableFunctions";

const ActiveApplications = ({
  setActiveTab = () => {},
  isInternalUser = false,
  setScrollToDetailSectionId = () => {},
  activeApplicationHistory = [],
}) => {
  return (
    <>
      {Array.isArray(activeApplicationHistory) ? (
        activeApplicationHistory.length > 0 ? (
          <>
            <p className="profile_section-title"> Active Applications </p>
            <div
              className="card mb-4 user-profile py-4"
              style={{ cursor: "pointer" }}
            >
              {activeApplicationHistory.map((app, index, arr) => (
                <div
                  className="card-body text-muted py-1"
                  key={`${app.id}_${index}`}
                  onClick={() => {
                    setActiveTab("Active Applications");
                    setScrollToDetailSectionId(app.id);
                  }}
                >
                  {isInternalUser && (
                    <Row>
                      <Col className="align-self-center">
                        <h5 className="profile_subsection-title lh-sm">
                          {app?.text}
                        </h5>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    {isInternalUser ? (
                      <Col>
                        <p className="profile_subsection-subtitle mb-0">
                          {convertStageCodeToName(
                            app?.stage,
                            app?.pitch_response?.response,
                            <span className="text-center">Unknown</span>
                          )}
                        </p>
                      </Col>
                    ) : (
                      <Col>
                        <h5 className="profile_subsection-title lh-sm mb-0">
                          {app?.text}
                        </h5>
                      </Col>
                    )}
                    <Col className=" d-flex justify-content-end">
                      <p className="profile_subsection-body mb-0">
                        {new Date(app?.created_at) >
                        getThreeMonthsOldDate(Date.now())
                          ? formatDate(app?.created_at)
                          : ""}
                      </p>
                    </Col>
                  </Row>
                  {arr.length - 1 !== index && <hr />}
                </div>
              ))}
            </div>
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
};

export default ActiveApplications;
