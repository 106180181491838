import React, { useCallback, useEffect, useRef, useState } from "react";

import HistoryDetailsLayout from "./HistoryDetailsLayout";
import { getPositionHistory } from "services/FetchApiData";
import { StageHistory } from "components/CandidateProfileModalContent/SubComponents";

const PitchHistoryDetails = ({
  data,
  pageNo,
  isInternalUser,
  scrollToDetailSectionId = "",
  setScrollToDetailSectionId = () => {},
  pitchHistory = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [positionHistory, setPositionHistory] = useState([]);

  const divRefs = useRef([]);

  const fetchCandidatePositionHistory = useCallback(
    async (position_id) => {
      try {
        setLoading(position_id);
        const resonse = await getPositionHistory(
          data?.candidate_id,
          position_id
        );
        setPositionHistory((prev) => [
          ...prev,
          {
            position_id,
            history:
              resonse.data.data.pipelines.candidate_position_history.data,
          },
        ]);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading("");
      }
    },
    [data?.candidate_id]
  );

  const handleToggle = useCallback(
    (positionId) => {
      fetchCandidatePositionHistory(positionId);
    },
    [fetchCandidatePositionHistory]
  );

  useEffect(() => {
    divRefs.current[scrollToDetailSectionId]?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });

    return () => setScrollToDetailSectionId("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToDetailSectionId]);

  return (
    pitchHistory.length > 0 && (
      <>
        <p className="mb-3 profile_section-title">{"Pitch History Details"}</p>
        {pitchHistory.map((current, i) => (
          <HistoryDetailsLayout
            key={i}
            id={i}
            pageNo={pageNo}
            ref={(element) => {
              divRefs.current[current?.position_id] = element;
            }}
            current={current}
            handleToggle={handleToggle}
            accordionBody={
              positionHistory?.length > 0 ? (
                <StageHistory
                  stageHistory={
                    positionHistory?.find(
                      (el) => el.position_id === current?.position_id
                    )?.history
                  }
                  isInternalUser={isInternalUser}
                />
              ) : (
                <p className="p-2 text-muted">No Stage History Found!</p>
              )
            }
            loading={loading}
          />
        ))}
      </>
    )
  );
};

export default PitchHistoryDetails;
