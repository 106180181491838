import React from "react";
import { Button, Spinner } from "react-bootstrap";

import "./Button.scss";

const ButtonUI = (props) => {
  return (
    <Button
      className={`btn-primary ${
        props?.outline ? props.outline : ""
      } flex-shrink-0 align-self-baseline`}
      size="sm"
      onClick={props?.onClick}
      disabled={props.loading || props.disabled}>
      {props.loading ? (
        <>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Loading...
        </>
      ) : (
        <>
          {props.title}
          {props.arrow && (
            <svg
              className="ms-1 align-baseline arrow"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="#6754E2"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M1 3.5C0.723858 3.5 0.5 3.72386 0.5 4C0.5 4.27614 0.723858 4.5 1 4.5L1 3.5ZM11.3536 4.35355C11.5488 4.15829 11.5488 3.84171 11.3536 3.64645L8.17157 0.464467C7.97631 0.269205 7.65973 0.269204 7.46447 0.464467C7.2692 0.659729 7.2692 0.976311 7.46447 1.17157L10.2929 4L7.46447 6.82843C7.2692 7.02369 7.2692 7.34027 7.46447 7.53553C7.65973 7.7308 7.97631 7.7308 8.17157 7.53553L11.3536 4.35355ZM1 4.5L11 4.5L11 3.5L1 3.5L1 4.5Z" />
            </svg>
          )}
        </>
      )}
    </Button>
  );
};

export default ButtonUI;
