import React from "react";
import { Col, Row } from "react-bootstrap";

import SelectUI from "../Select/SelectUI";
import { selectOptionConverter } from "../../helpers/ReusableFunctions";
import "../../pages/PermissionRequest/PermissionRequest.scss";
import { userTypeOption } from "helpers/Constant";

const BasicInfoPR = () => {
  const reportOption = ["Pipeline Report"];
  const actionOption = ["Action"];
  const salaryOption = ["Salary"];

  const handleSubmit = () => {};

  return (
    <Row>
      <Col md={6}>
        <div className="add-permission-card">
          <h4>Edit Report Permission</h4>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <div className="user-label">Choose Report</div>
                <SelectUI
                  options={selectOptionConverter(reportOption)}
                  className="add-class-select"
                  defaultValue={true}
                />

                <div className="user-label">Action</div>
                <SelectUI
                  options={selectOptionConverter(actionOption)}
                  className="add-class-select"
                  defaultValue={true}
                />
              </Col>
              <Col md={6}>
                <div className="user-label">Choose User Type</div>
                <SelectUI
                  options={selectOptionConverter(userTypeOption)}
                  className="add-class-select"
                  defaultValue={true}
                />

                <div className="user-label">Select Fields</div>
                <SelectUI
                  options={selectOptionConverter(salaryOption)}
                  className="add-class-select"
                  defaultValue={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="user-card-button">
                <div className="action-button mb-4">
                  <div className="action-text">Select</div>
                </div>
                <div className="action-button-outline">
                  <div className="action-text">Cancel</div>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Col>

      <Col md={6}>
        <div className="add-permission-card">
          <h4>Add / Remove Subfields to Reports</h4>
          <hr></hr>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <div className="user-label">Choose Report</div>
                <SelectUI
                  options={selectOptionConverter(reportOption)}
                  className="add-class-select"
                  defaultValue={true}
                />
              </Col>
              <Col md={6}>
                <div className="user-label">Select Fields</div>
                <SelectUI
                  options={selectOptionConverter(salaryOption)}
                  className="add-class-select"
                  defaultValue={true}
                />
              </Col>
              <Col md={12}>
                <div className="user-label">Action</div>
                <SelectUI
                  options={selectOptionConverter(actionOption)}
                  className="add-class-select"
                  defaultValue={true}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="user-card-button">
                <div className="action-button mb-4">
                  <div className="action-text">Select</div>
                </div>
                <div className="action-button-outline">
                  <div className="action-text">Cancel</div>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Col>
    </Row>
  );
};

export default BasicInfoPR;
