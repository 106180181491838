import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { isEmpty } from "lodash";

import "./Dashboard.scss";

import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";
import {
  compareRangeInterval,
  getCandidatePipelineData,
  getPitchPerformance,
  getRecentlyActiveCandidatesMetrics,
  getSuccessKPI,
  getTimeToHire,
} from "services/FetchApiData";

import DonutChart from "components/Charts/DonutChart";
import LineRecharts from "components/Charts/LineRecharts";
import BarRecharts from "components/Charts/BarRecharts";
import DashboardCard from "components/Dashboard/DashboardCard";
import ButtonUI from "components/UI/Button/ButtonUI";
import Square from "components/UI/Square/Square";
import FilterNav from "components/FilterNav/FilterNav";
import {
  ChartsColor,
  DefaultBarData,
  PAGES,
  dashboardLineChartDataLabels,
  dashboardLineChartDatakeys,
  pieChartNameConvention,
} from "helpers/Constant";
import {
  calculateMetricsForTimeToHire,
  calculateStatsForSuccessKpi,
  capitalizeLetter,
  convertData,
  convertISODateRangeToLocalDateRange,
  nameConvention,
  getCurrentPageAllFiltersFromFilterStore,
} from "helpers/ReusableFunctions";
import SuccessKpiStats from "pages/PipelineReport/components/SuccessKpiStats";
import TimeToHireStats from "pages/PipelineReport/components/TimeToHireStats";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setSelectedGraph, language } = useContext(UserContext);
  const {
    filterStore,
    setFilterStore,
    setLoading,
    isPresentationEnabled,
    Clients,
    externalClient,
    setLastAppliedFilterstore,
    applyFilterFlags: { applyFilterInDashboard },
    filterStoreToPassWhenTopFiltersChange,
    dropdownData,
    initialFilterStore,
    isInternalUser,
  } = useContext(FilterContext);

  let stage_1 = {},
    stage_2 = {};
  stage_1 = dropdownData.StageManagersOptions.find(
    (option) => option.value === "introduced"
  );
  stage_2 = dropdownData.StageManagersOptions.find(
    (option) => option.value === "offer"
  );

  const stageSelected = dropdownData.StageManagersOptions.find(
    (option) => option.value === "introduced"
  );

  const initialLoaderValue = { piechart: false, linechart: false };
  const [isLoader, setIsLoader] = useState(initialLoaderValue);
  const [pieData, setPieData] = useState();
  const [lineChartData, setLineChartData] = useState([]);
  const [candidateMetrics, setCandidateMetricsData] = useState([]);
  const [isLoadSuccessKpiMetric, setIsLoadSuccessKpiMetric] = useState(false);
  const [successKpiMetricsData, setSuccessKpiMetricsData] = useState([]);
  const [isLoadTimeToHireMetric, setIsLoadTimeToHireMetric] = useState(false);
  const [timeToHireMetricsData, setTimeToHireMetricsData] = useState([]);
  const [
    isLoadRecentlyActiveCandidatesMetric,
    setIsLoadRecentlyActiveCandidatesMetric,
  ] = useState(false);
  const [
    recentlyActiveCandidatesMetricsData,
    setRecentlyActiveCandidatesMetricsData,
  ] = useState(0);
  const [activePipelineMetricsData, setActivePipelineMetricsData] = useState(
    []
  );
  const [isLoadActivePipelineMetricsData, setIsLoadActivePipelineMetricsData] =
    useState(false);
  const [initialRenderFlag, setInitialRenderFlag] = useState(true);

  const handleLoader = (obj) => {
    setIsLoader((prevLoader) => ({
      ...prevLoader,
      ...obj,
    }));
  };

  const fetchData = async (modifiedFilterStore) => {
    try {
      setLoading(true);
      handleLoader({ piechart: true });
      const response = await getPitchPerformance(
        getCurrentPageAllFiltersFromFilterStore(
          modifiedFilterStore,
          isInternalUser,
          PAGES.DASHBOARD
        ),
        Clients
      );
      const rawData = await response.data.data?.reports?.pitch_performance[0]
        .data;

      setPieData(convertData(rawData));
      setLastAppliedFilterstore(modifiedFilterStore);
    } catch (error) {
      console.warn("Data not found");
    } finally {
      setLoading(false);
      handleLoader({ piechart: false });
    }
  };

  const handleGoToReports = (initialChart) => {
    setSelectedGraph(initialChart);
    if (initialChart === "Pie Chart") {
      setFilterStore((prevFilterStore) => ({
        ...prevFilterStore,
        PitchResponse: "yes",
      }));
    }
    navigate("/performancereport");
  };

  const fetchLineChartData = useCallback(async (modifiedFilterstore) => {
    const convertReportValue = (rawData, dateRange) => {
      return {
        yes: rawData.percent_applied,
        no: rawData.percent_declined,
        maybe: rawData.percent_maybe,
        range: JSON.stringify(dateRange),
      };
    };

    try {
      setLoading(true);
      handleLoader({ linechart: true });
      setLineChartData([]);
      let dataList = [];

      const payload = {
        ...modifiedFilterstore,
        count: modifiedFilterstore.count,
        endDate: modifiedFilterstore.endDate,
        startDate: modifiedFilterstore.startDate,
        unit: modifiedFilterstore.unit,
      };

      const response = await compareRangeInterval(
        getCurrentPageAllFiltersFromFilterStore(
          payload,
          isInternalUser,
          PAGES.DASHBOARD
        ),
        Clients
      );
      const convertedData =
        response.data.data?.reports.pitch_performance_interval;

      const companyIntervalData = convertedData.filter(
        (item) => (item.benchmark = "company")
      )[0].intervals;
      companyIntervalData.forEach((item) => {
        const tempRange = convertISODateRangeToLocalDateRange(item?.range);
        dataList.push(convertReportValue(item.data, tempRange));
      });

      setLineChartData(dataList);
      setLastAppliedFilterstore((prevFilterStore) => ({
        ...prevFilterStore,
        ...payload,
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      handleLoader({ linechart: false });
    }
    // eslint-disable-next-line
  }, []);

  const fetchActivePipelineMetricsData = async (fs) => {
    try {
      setIsLoadActivePipelineMetricsData(true);
      const response = await getCandidatePipelineData(
        getCurrentPageAllFiltersFromFilterStore(
          fs,
          isInternalUser,
          PAGES.DASHBOARD
        ),
        Clients,
        isPresentationEnabled
      );
      setActivePipelineMetricsData(
        response.data.data.dashboard.active_pipeline_counts
      );
      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadActivePipelineMetricsData(false);
    }
  };

  const fetchSuccessKpiData = async (fs) => {
    if (isEmpty(stageSelected)) return;
    try {
      setIsLoadSuccessKpiMetric(true);
      const response = await getSuccessKPI(
        {
          ...fs,
          StageManagers: stageSelected,
        },
        Clients
      );
      const { metricsData } = calculateStatsForSuccessKpi(
        response,
        "introduced"
      );
      setSuccessKpiMetricsData(metricsData);
      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadSuccessKpiMetric(false);
    }
  };

  const fetchTimeToHireData = async (fs) => {
    if (isEmpty(stage_1) || isEmpty(stage_2)) return;
    try {
      setIsLoadTimeToHireMetric(true);
      const response = await getTimeToHire(
        {
          ...fs,
          StageManagers1: stage_1,
          StageManagers2: stage_2,
        },
        Clients,
        isPresentationEnabled
      );
      const { metricsData } = calculateMetricsForTimeToHire(response);
      setTimeToHireMetricsData(metricsData);
      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadTimeToHireMetric(false);
    }
  };

  const fetchRecentlyActiveCandidatesData = async (fs) => {
    if (!isInternalUser) return;
    try {
      setIsLoadRecentlyActiveCandidatesMetric(true);
      const response = await getRecentlyActiveCandidatesMetrics(
        getCurrentPageAllFiltersFromFilterStore(
          fs,
          isInternalUser,
          PAGES.DASHBOARD
        ),
        Clients
      );
      setRecentlyActiveCandidatesMetricsData(
        response.data.data.dashboard.recently_active_candidates_counts
          ?.total_candidates || 0
      );
      setLastAppliedFilterstore(fs);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadRecentlyActiveCandidatesMetric(false);
    }
  };

  const getCandidateMetricsData = useCallback(() => {
    const metricsData = [
      {
        stage: "Candidates",
        count: activePipelineMetricsData?.total_candidates,
      },
      {
        stage: "Interviews",
        count: activePipelineMetricsData?.active_interviews,
      },
      {
        stage: "Final Interviews",
        count: activePipelineMetricsData?.final_interviews,
      },
      {
        stage: "Offers",
        count: activePipelineMetricsData?.offers,
      },
    ];
    setCandidateMetricsData(metricsData);
  }, [activePipelineMetricsData]);

  useEffect(() => {
    const final_filter_store = initialRenderFlag
      ? initialFilterStore
      : filterStore;
    fetchActivePipelineMetricsData(final_filter_store);

    return () => setActivePipelineMetricsData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPresentationEnabled,
    applyFilterInDashboard,
    filterStore.startDate,
    filterStore.endDate,
  ]);

  useEffect(() => {
    if (!initialRenderFlag) {
      fetchData(filterStoreToPassWhenTopFiltersChange);
      fetchSuccessKpiData(filterStoreToPassWhenTopFiltersChange);
      fetchTimeToHireData(filterStoreToPassWhenTopFiltersChange);
      fetchRecentlyActiveCandidatesData(filterStoreToPassWhenTopFiltersChange);
    }
    // eslint-disable-next-line
  }, [filterStore.startDate, filterStore.endDate]);

  useEffect(() => {
    const final_filter_store = initialRenderFlag
      ? initialFilterStore
      : filterStore;
    fetchData(final_filter_store);
    fetchSuccessKpiData(final_filter_store);
    fetchTimeToHireData(final_filter_store);
    fetchRecentlyActiveCandidatesData(final_filter_store);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    applyFilterInDashboard,
    isPresentationEnabled,
    dropdownData.StageManagersOptions,
  ]);

  useEffect(() => {
    if (filterStore.count > 0) {
      fetchLineChartData(filterStore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchLineChartData, applyFilterInDashboard, isPresentationEnabled]);

  useEffect(() => {
    if (filterStore.count > 0) {
      fetchLineChartData(filterStoreToPassWhenTopFiltersChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterStore.startDate,
    filterStore.endDate,
    filterStore.unit,
    filterStore.count,
  ]);

  useEffect(() => {
    getCandidateMetricsData();
  }, [getCandidateMetricsData]);

  useEffect(() => {
    setInitialRenderFlag(false);
  }, []);

  return (
    <>
      {isPresentationEnabled && externalClient && (
        <h4 className="text-center">{`You are currently viewing ${externalClient?.label}'s Metrics `}</h4>
      )}
      <h4>{t("dashboardText.dashboard")}</h4>
      <FilterNav />

      <Row>
        {isInternalUser && (
          <>
            <DashboardCard
              title={"Internal Candidate Pipeline Report"}
              isLoading={isLoadActivePipelineMetricsData}
              headerContent={
                <ButtonUI
                  title={t("dashboardText.goToReports")}
                  arrow={true}
                  onClick={() => navigate("/candidatepipelinereport")}
                />
              }
            >
              <div className="row h-100 justify-content-center align-content-center py-5 g-4">
                {candidateMetrics.map((el, index) => {
                  const color = ChartsColor[index % 3];
                  return (
                    <div className="text-center col" key={index}>
                      <h1 className="card-title" style={{ color }}>
                        {el.count}
                      </h1>
                      <h5> {el?.stage} </h5>
                    </div>
                  );
                })}
              </div>
            </DashboardCard>

            <ActivePipelineMetrics
              activePipelineMetricsData={activePipelineMetricsData}
              language={language}
              isLoading={isLoadActivePipelineMetricsData}
            />
          </>
        )}

        <DashboardCard
          title={t("dashboardText.lineChartTitle")}
          headerContent={
            <ButtonUI
              title={t("dashboardText.goToReports")}
              arrow={true}
              onClick={() => handleGoToReports("Line Chart")}
            />
          }
        >
          <LineRecharts
            lineData={lineChartData}
            loading={isLoader.linechart}
            datakeys={dashboardLineChartDatakeys}
            dataLabels={dashboardLineChartDataLabels}
            yAxisLabelName={t("barChartText.applicantRates")}
            isSmall={true}
          />
        </DashboardCard>

        <BrandingReportTimeMetrics
          language={language}
          handleGoToReports={handleGoToReports}
          pieData={pieData}
          isLoader={isLoader}
        />

        {!isInternalUser && (
          <>
            <ActivePipelineMetrics
              activePipelineMetricsData={activePipelineMetricsData}
              language={language}
              isLoading={isLoadActivePipelineMetricsData}
            />
          </>
        )}

        <DashboardCard
          title={"Success KPI Report"}
          isLoading={isLoadSuccessKpiMetric}
          headerContent={
            <ButtonUI
              title={t("dashboardText.goToReports")}
              arrow={true}
              onClick={() =>
                navigate("/successkpireport", {
                  state: {
                    filters: {
                      StageManagers: stageSelected,
                    },
                  },
                })
              }
            />
          }
        >
          <div className="h-100 d-flex flex-column justify-content-center align-items-center py-5">
            <SuccessKpiStats
              stageSelected={t("cardViewText.introduced")}
              metricsData={successKpiMetricsData}
              hideLineChart={true}
              isInternalUser={isInternalUser}
              loading={isLoadSuccessKpiMetric}
            />
          </div>
        </DashboardCard>

        <DashboardCard
          title={"Time To Hire Report"}
          isLoading={isLoadTimeToHireMetric}
          headerContent={
            <ButtonUI
              title={t("dashboardText.goToReports")}
              arrow={true}
              onClick={() => {
                navigate("/timetohirereport", {
                  state: {
                    filters: {
                      StageManagers1: stage_1,
                      StageManagers2: stage_2,
                    },
                  },
                });
              }}
            />
          }
        >
          <div className="h-100 d-flex flex-column justify-content-center align-items-center py-5">
            <TimeToHireStats
              metricsData={timeToHireMetricsData}
              isInternalUser={isInternalUser}
              hideLineChart={true}
              loading={isLoadTimeToHireMetric}
            />
          </div>
        </DashboardCard>

        {isInternalUser && (
          <DashboardCard
            title={"Recently Active Candidates Report"}
            isLoading={isLoadRecentlyActiveCandidatesMetric}
            headerContent={
              <>
                <ButtonUI
                  title={t("dashboardText.goToReports")}
                  arrow={true}
                  onClick={() => navigate("/recentlyactivecandidatesreport")}
                />
              </>
            }
          >
            <div className="d-flex flex-column justify-content-center align-items-center py-5 my-auto">
              <h1
                className="card-title"
                style={{
                  color: ChartsColor[0],
                }}
              >
                {recentlyActiveCandidatesMetricsData}
              </h1>
              <h6 className="metrics-label text-center lh-sm">
                {" "}
                Total Candidates
              </h6>
            </div>
          </DashboardCard>
        )}

        <DashboardCard
          title={"Interview Performance Report"}
          className="card-with-background"
          headerContent={
            <>
              {/* <ButtonUI
                  title={t("dashboardText.goToReports")}
                  arrow={true}
                  onClick={() => handleGoToReports("Bar Chart")}
                /> */}
            </>
          }
        >
          <BarRecharts
            detailedBarData={DefaultBarData}
            dataKeys={["value", "value1", "value2"]}
            loading={false}
          />
        </DashboardCard>
      </Row>
    </>
  );
};

export default Dashboard;

const ActivePipelineMetrics = ({
  activePipelineMetricsData,
  language,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <DashboardCard
      title={"Active Pipeline Report"}
      isLoading={isLoading}
      headerContent={
        <ButtonUI
          title={t("dashboardText.goToReports")}
          arrow={true}
          onClick={() => {
            navigate("/activepipelinereport");
          }}
        />
      }
    >
      <div className="row h-100 justify-content-center align-content-center py-5 g-4">
        <div className="text-center col">
          <h1 className="card-title" style={{ color: ChartsColor[0] }}>
            {(activePipelineMetricsData?.resume_screening || 0) +
              (activePipelineMetricsData?.testing || 0)}
          </h1>
          <h5>Resume Review & Testing</h5>
        </div>
        <div className="text-center col">
          <h1 className="card-title" style={{ color: ChartsColor[1] }}>
            {activePipelineMetricsData?.active_interviews}
          </h1>
          <h5>Interviews</h5>
        </div>
        <div className="text-center col">
          <h1 className="card-title" style={{ color: ChartsColor[2] }}>
            {activePipelineMetricsData?.offers}
          </h1>
          <h5>Offers</h5>
        </div>
      </div>
    </DashboardCard>
  );
};

const BrandingReportTimeMetrics = ({
  language,
  handleGoToReports,
  pieData,
  isLoader,
}) => {
  const { t } = useTranslation();
  return (
    <DashboardCard
      title={t("dashboardText.barChartTitle")}
      headerContent={
        <ButtonUI
          title={t("dashboardText.goToReports")}
          arrow={true}
          onClick={() => {
            handleGoToReports("Pie Chart");
          }}
        />
      }
    >
      {" "}
      <Row>
        <Col md={8}>
          <DonutChart
            data={pieData}
            colors={ChartsColor}
            loading={isLoader.piechart}
          />
        </Col>
        <Col md={4} className="align-self-center">
          <div className="d-flex flex-column gap-4 align-items-start mt-2">
            {!isLoader.piechart &&
              pieData?.map((entry, index) => {
                return (
                  <div className="d-flex gap-2 align-items-center " key={index}>
                    <Square
                      value={`${entry?.value ?? "0"}%`}
                      color={ChartsColor[index]}
                    />
                    <h5>
                      {" "}
                      {capitalizeLetter(
                        nameConvention(pieChartNameConvention, entry)?.value
                      )}
                    </h5>
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
    </DashboardCard>
  );
};
