import React, { useContext, useState } from "react";
import Select, { components } from "react-select";
import "./SelectUI.scss";
import { FilterContext } from "context/FilterContext";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}>
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => null}
        style={{
          accentColor: "var(--border2)",
          marginRight: "4px",
          border: "3px solid var(--border2)",
        }}
      />
      {children}
    </components.Option>
  );
};

const SelectUI = ({
  placeholder,
  options,
  isMulti,
  defaultValue,
  className = "",
  selectedValue,
  inheritValue,
  isDisabled,
  value,
  entryName,
  isClearable,
  formatOptionLabel,
  isRenderInputCheckbox = false,
  allowSelectAll = false,
}) => {
  const { filterStore } = useContext(FilterContext);

  const handleSelectChange = (e) => {
    if (selectedValue) selectedValue(e, entryName);
  };

  const selectAllOption = {
    value: "*",
    label: "Select ALL",
  };

  const MultiValue = (props) => {
    if (props.data.label !== "Select ALL") {
      return (
        <components.MultiValue {...props}>
          <span>{props.data.label}</span>
        </components.MultiValue>
      );
    }
  };

  return (
    <Select
      maxMenuHeight={170}
      menuPlacement={"auto"}
      className={`${className}`}
      classNamePrefix="react-select"
      onChange={(selected, e) => {
        if (allowSelectAll) {
          if (selected !== null && selected.length > 0) {
            if (selected[selected.length - 1].value === selectAllOption.value) {
              return handleSelectChange(
                [selectAllOption, ...options],
                entryName
              );
            }
            let result = [];
            if (selected.length === options.length) {
              if (
                e.action === "remove-value" ||
                (e.action === "deselect-option" &&
                  selected.some((item) => item.value === "*"))
              ) {
                result = selected.filter(
                  (option) => option.value !== selectAllOption.value
                );
              } else if (
                e.action === "deselect-option" &&
                !!!selected.find((item) => item.value === "*")
              ) {
                result = [];
              } else if (e.action === "select-option") {
                result = [selectAllOption, ...options];
              }

              return handleSelectChange(result, entryName);
            }
          }
          return handleSelectChange(selected, entryName);
        } else {
          return handleSelectChange(selected, entryName);
        }
      }}
      placeholder={placeholder}
      value={filterStore[entryName] || value}
      defaultValue={!value ? defaultValue && options[inheritValue || 0] : value}
      options={allowSelectAll ? [selectAllOption, ...options] : options}
      closeMenuOnSelect={isMulti === true ? false : true}
      isMulti={isMulti === true ? true : false}
      isDisabled={isDisabled}
      isOptionDisabled={(option) => option.isDisabled}
      isClearable={isClearable || false}
      styles={{
        option: (base, { data }) => ({
          ...base,
          borderBottom: data.value === "Custom" ? "1px solid" : "none",
          borderColor: "var(--border2)",
        }),
      }}
      formatOptionLabel={formatOptionLabel}
      {...(isRenderInputCheckbox && {
        components: {
          Option: InputOption,
          MultiValue,
        },
        hideSelectedOptions: false,
      })}
    />
  );
};

export default SelectUI;
