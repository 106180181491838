import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import "./AllReports.scss";

import MiniCard from "./MiniCard";

import {
  reportCardsDataForExternal,
  reportCardsDataForAll,
  reportsCategoryColors,
  internalReportsPath,
  reportCardsDataForBothUser,
} from "helpers/Constant";
import { UserContext } from "context/UserContext";
import { FilterContext } from "context/FilterContext";
import { deleteCustomReport } from "services/FetchApiData";
import { isJSON } from "helpers/ReusableFunctions";

const ListOfReports = ({ isDescription }) => {
  const { setCustomReports, customReports } = useContext(UserContext);
  const { isPresentationEnabled, isInternalUser } = useContext(FilterContext);
  const navigate = useNavigate();

  const [isDeletingCustomReport, setIsDeletingCustomReport] = useState(false);

  const handleDeleteCustomReport = async (id, closeModal = () => {}) => {
    try {
      setIsDeletingCustomReport(true);
      const res = await deleteCustomReport(id);
      setCustomReports((prevReports) =>
        prevReports.filter(
          (report) => report.id !== res.data.data.delete_report
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsDeletingCustomReport(false);
      closeModal();
    }
  };

  return (
    <div className="mt-4 list-of-reports">
      {/* <Row className="report-card mt-4 g-3">
        <h5 style={{ marginLeft: "16px" }}>Top Used</h5>
        {[
          ...(isExternalGroup || isPresentationEnabled
            ? reportCardsDataForExternal
            : reportCardsDataForAll),
        ].map((report, index) => (
          <Col key={index} lg={4} md={4} sm={6}>
            <MiniCard
              title={report?.title}
              color={reportsCategoryColors["top"]}
              isDescription={isDescription}
              container={"TopUsed"}
              description={report?.description}
              handleNavigation={() => navigate(report?.navigate)}
            />
          </Col>
        ))}
      </Row> */}
      {customReports?.length > 0 && (
        <Row className="report-card mt-4 g-3">
          <h5 style={{ marginLeft: "16px" }}>Custom Reports</h5>
          {customReports
            .sort(
              (reportA, reportB) =>
                new Date(reportB.updated) - new Date(reportA.updated)
            )
            .map(({ id, version, payload }, index) => {
              const parsedPayload = isJSON(payload) ? JSON.parse(payload) : {};

              return (
                <Col key={index} lg={4} md={4} sm={6}>
                  <MiniCard
                    id={id}
                    title={parsedPayload?.title}
                    color={reportsCategoryColors["custom"]}
                    isDescription={isDescription}
                    description={parsedPayload?.description}
                    handleDeleteReport={handleDeleteCustomReport}
                    isDisabled={
                      parsedPayload?.accessUserType === "internal" &&
                      isPresentationEnabled &&
                      internalReportsPath.includes(parsedPayload?.navigate)
                    }
                    isDeletable={true}
                    showDeleteLoader={isDeletingCustomReport}
                    handleNavigation={(e) => {
                      e.stopPropagation();
                      navigate(parsedPayload?.navigate, {
                        state: {
                          customReportDetail: {
                            id,
                            version,
                            ...parsedPayload,
                          },
                        },
                      });
                    }}
                  />
                </Col>
              );
            })}
        </Row>
      )}
      <Row className="report-card mt-4 g-3">
        <h5 style={{ marginLeft: "16px" }}>All Reports</h5>
        {[
          ...(isInternalUser
            ? reportCardsDataForAll
            : reportCardsDataForExternal.concat(reportCardsDataForBothUser)),
        ].map((report, index) => (
          <Col key={index} lg={4} md={4} sm={6}>
            <MiniCard
              title={report?.title}
              color={reportsCategoryColors["all"]}
              isDescription={isDescription}
              description={report?.description}
              handleNavigation={() => navigate(report?.navigate)}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ListOfReports;
