import React, { useEffect } from "react";
import { debounce } from "@mui/material/utils";
import SearchIcon from "assets/svg/search-icon.svg";

const SearchUI = ({ apiRef }) => {
  const [searchValue, setSearchValue] = React.useState("");

  const updateSearchValue = React.useMemo(() => {
    return debounce((newValue) => {
      apiRef?.current.setQuickFilterValues(
        newValue.split(" ").filter((word) => word !== "")
      );
    }, 500);
  }, [apiRef]);

  function handleSearchValueChange(event) {
    const newValue = event.target.value;
    setSearchValue(newValue);
    updateSearchValue(newValue);
  }

  useEffect(() => {
    setSearchValue("");
    apiRef?.current.setQuickFilterValues("");
  }, [apiRef]);

  return (
    <div className="search-bar">
      <div className="search-input">
        <img src={SearchIcon} alt="search" loading="lazy" />
        <input
          placeholder="Search"
          name="search"
          type="text"
          id="searchtext"
          className="form-control"
          autoComplete="off"
          value={searchValue}
          onChange={handleSearchValueChange}
        />
      </div>
    </div>
  );
};

export default SearchUI;
