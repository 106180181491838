import Toast from "react-bootstrap/Toast";
import "./toast.scss";

function CustomToast({
  show,
  setShow,
  header = "",
  message = "",
  icon,
  className,
}) {
  return (
    <div
      className={`toast-container position-absolute top-0 end-0 ${className}`}
    >
      <Toast
        show={show}
        onClose={() => setShow(false)}
        // position="top-end"
        delay={3500}
        autohide
      >
        {header && (
          <Toast.Header>
            {icon}
            <strong className="me-auto">{header}</strong>
          </Toast.Header>
        )}
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
}

export default CustomToast;
