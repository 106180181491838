import { useLocation } from "react-router-dom";

import {
  EmployeeRoleChoices,
  differentReportsPath,
  internalReportsPath,
} from "helpers/Constant";

import { formatDate, convertStageCodeToName } from "helpers/ReusableFunctions";
import { useTranslation } from "react-i18next";

const StageHistory = ({
  stageHistory = [],
  isInternalUser = false,
  pitchResponse = {},
}) => {
  const { t } = useTranslation();

  const location = useLocation();

  return (
    <div className="d-flex flex-column gap-4 mt-3">
      {stageHistory
        ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .map((position, i) => {
          return (
            <div key={i} className="card p-3">
              <div className="d-flex justify-content-start ">
                <div className="profile_subsection-title">
                  Stage:{" "}
                  <span className="profile_subsection-subtitle">
                    {convertStageCodeToName(
                      position?.stage,
                      pitchResponse?.response ||
                        position?.pitch_response?.response,
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-start ">
                {/* <div className="text-muted fs-6">ExecutiveSearch.AI</div> */}
                <div className="profile_subsection-body">
                  {formatDate(position.updated_at)}
                </div>
              </div>

              {position.comments && position.comments.length > 0 && (
                <div className="overflow-y-auto">
                  <hr className="my-2" />
                  <p className="profile_subsection-title">
                    {t("cardViewText.notes")}
                  </p>
                  {position.comments
                    .sort(
                      (a, b) => new Date(b.created_at) - new Date(a.created_at)
                    )
                    .map((comment, j) => (
                      <div
                        className="my-3 d-flex justify-content-between gap-4 flex-column flex-sm-row"
                        key={j}
                      >
                        <div className="profile_subsection-body">
                          {comment.text}
                        </div>
                        <div
                          className="d-flex flex-column justify-content-start align-items-end"
                          style={{
                            flexShrink: 0,
                          }}
                        >
                          <div className="profile_subsection-subtitle">
                            {EmployeeRoleChoices[
                              `${position?.commenter?.role}`
                            ] ??
                              (internalReportsPath.includes(
                                location.pathname
                              ) ||
                              (differentReportsPath.includes(
                                location.pathname
                              ) &&
                                isInternalUser)
                                ? `${comment?.commenter?.first_name} ${comment?.commenter?.last_name}`
                                : `ESAI`)}
                          </div>
                          <div className="profile_subsection-body">
                            {formatDate(comment.created_at)}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default StageHistory;
