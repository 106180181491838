import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import backSvg from "../../assets/svg/go-back.svg";
import ButtonUI from "components/UI/Button/ButtonUI";

const PageTitle = ({
  title,
  back,
  allowLeaderboardAccess = false,
  navigateLeaderboardFrom = "",
  navigateBack = "",
  isDisabledToNavigateLeaderboard = false,
  leaderboardAccessIds = {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = () => {
    if (navigateBack) {
      const backTo = `/${navigateBack}`;
      navigate(backTo, {
        state: {
          isPrevLeader: location.pathname.includes("/leaderboard"),
          prevTitle: title.split("Leaderboard")[0],
        },
      });
    } else {
      navigate("/reports");
    }
  };

  const handleNavigateToLeaderboard = () => {
    navigate(`${navigateLeaderboardFrom}/leaderboard`, {
      state: {
        isNavigateToLeaderboard: true,
        currentPageTitle: `${title} Leaderboard`,
        backToPageTitle: title,
        leaderboard_access_ids: leaderboardAccessIds,
      },
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-2 flex-grow-1">
          <img
            src={backSvg}
            alt="back"
            className="mb-2 cursor-pointer"
            onClick={goBack}
          />
          <h5 className="cursor-pointer" onClick={goBack}>
            {back}
          </h5>
        </div>
        {allowLeaderboardAccess && (
          <ButtonUI
            title={"Go To LeaderBoard"}
            arrow={true}
            loading={false}
            disabled={isDisabledToNavigateLeaderboard}
            onClick={handleNavigateToLeaderboard}
          />
        )}
      </div>
      <h4>{title}</h4>
    </div>
  );
};

export default PageTitle;
