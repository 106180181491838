const RadioOption = ({ selected, setSelected, compareWithRadioOptions }) => {
  return (
    <div className="comapare-with-radio">
      <hr className="radio-hr" />
      {compareWithRadioOptions.map((item) => (
        <div key={item.value} className="mt-2 comapare-with-radio-option">
          <input
            className="form-check-input"
            type="radio"
            name="compareWithType"
            id={item.value}
            value={item.value}
            defaultChecked={
              selected
                ? item.value === selected
                : item.value === compareWithRadioOptions[1].value
            }
            onChange={(e) => {
              setSelected(e.target.value);
            }}
            disabled={item.isDisabled}
          />
          <label className="form-check-label" htmlFor={item.value}>
            {item.label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioOption;
