import { Col, Row } from "react-bootstrap";
import { useContext, useState } from "react";

import "./style.scss";

import LineRecharts from "components/Charts/LineRecharts";
import { ChartsColor } from "helpers/Constant";
import ModalBox from "components/Modal/ModalBox";
import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";

const SuccessKpiStats = ({
  stageSelected,
  lineData = [],
  metricsData = [],
  hideLineChart = false,
  isInternalUser = false,
}) => {
  const submissionRatioChartColors = ["#10c1e5", "#e510be", "#152de5"];
  const externalReportDataKeys = ["company", "market", "industry"];
  const internalReportDataKeys = ["my", "all"];

  const [showModal, setShowModal] = useState(false);
  const [selectedLineIndex, setSelectedLineIndex] = useState(0);

  const { Clients, isPresentationEnabled } = useContext(FilterContext);
  const { User, userGroup, loggedInUserData } = useContext(UserContext);

  let finalDataKeys = isInternalUser
    ? internalReportDataKeys
    : externalReportDataKeys;

  const companyNameForExternalUser =
    userGroup.includes("internal") && isPresentationEnabled && Clients.value
      ? Clients?.label
      : User?.signInUserSession?.idToken?.payload["custom:alt_org_name"]
      ? User?.signInUserSession?.idToken?.payload?.["custom:alt_org_name"]
      : loggedInUserData?.organization?.name
      ? loggedInUserData?.organization?.name
      : "Company";

  const openModal = (selectedIndex) => {
    setSelectedLineIndex(selectedIndex);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setSelectedLineIndex("");
  };

  const getDataLabels = (index) => {
    let reportDataLabels = [];
    if (Array.isArray(lineData)) {
      if (lineData[index]?.[0]?.ratioType === "success") {
        if (isInternalUser) {
          reportDataLabels = [
            { name: `${stageSelected} Success` },
            { name: `Company ${stageSelected} Success` },
          ];
        } else {
          reportDataLabels = [
            { name: `${companyNameForExternalUser} ${stageSelected} Success` },
            { name: `Market ${stageSelected} Success` },
            { name: `Industry ${stageSelected} Success` },
          ];
        }
      } else {
        if (isInternalUser) {
          reportDataLabels = [
            { name: `Total ${stageSelected} Submissions` },
            { name: `Total Company ${stageSelected} Submissions` },
          ];
        } else {
          reportDataLabels = [
            {
              name: `Total ${companyNameForExternalUser} ${stageSelected} Submissions`,
            },
            // { name: `Total Market ${stageSelected} Submissions` },
            // { name: `Total Industry ${stageSelected} Submissions` },
          ];
        }
      }
    }
    return reportDataLabels;
  };

  function getCustomKeyName(dataKey, ratioType) {
    let username =
      dataKey === "my"
        ? ``
        : dataKey === "all"
        ? `Company`
        : dataKey === "market"
        ? `Market`
        : dataKey === "industry"
        ? `Industry`
        : dataKey === "company"
        ? userGroup.includes("internal") &&
          isPresentationEnabled &&
          Clients.value
          ? Clients?.label
          : User?.signInUserSession?.idToken?.payload["custom:alt_org_name"]
          ? User?.signInUserSession?.idToken?.payload?.["custom:alt_org_name"]
          : loggedInUserData?.organization?.name
          ? loggedInUserData?.organization?.name
          : "Company"
        : "";

    if (ratioType === "success") {
      if (dataKey === "my") {
        return `${stageSelected} Success`;
      } else {
        return `${username} ${stageSelected} Success`;
      }
    }
    if (ratioType === "submission") {
      if (dataKey === "my") {
        return `Total ${stageSelected} Submissions`;
      } else {
        return `Total ${username} ${stageSelected} Submissions`;
      }
    }
  }

  return (
    <div className={`${hideLineChart ? "" : "card-layout"} w-100`}>
      <Row className="gap-2 gap-md-0 g-4">
        <Col md={hideLineChart ? 12 : 4}>
          <Row className="h-100 gap-2 gap-md-0">
            <Col className="flex-grow-1 col-12 col-md-6">
              <div
                className={`${
                  hideLineChart ? "" : "card-layout"
                } h-100 d-flex flex-row flex-md-column justify-content-around rounded-5 align-items-center m-0`}
                style={{
                  boxShadow: hideLineChart
                    ? "none"
                    : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                {finalDataKeys.map((key, i) => (
                  <div
                    className="d-flex flex-column justify-content-center align-items-center  align-self-baseline align-self-sm-auto w-100"
                    key={`${key}_${i}`}
                  >
                    <h1
                      className="card-title fw-bolder"
                      style={{ color: ChartsColor[i] }}
                    >
                      {`${
                        metricsData[i]?.totalSuccess
                          ? metricsData[i].totalSuccess
                          : 0
                      }%`}
                    </h1>
                    <h6 className="metrics-label text-center">
                      {getCustomKeyName(key, "success")}
                    </h6>
                  </div>
                ))}
              </div>
            </Col>
            <Col className="flex-grow-1 col-12 col-md-6">
              <div
                className={`${
                  hideLineChart ? "" : "card-layout"
                } h-100 d-flex flex-row flex-md-column justify-content-around rounded-5 m-0`}
                style={{
                  boxShadow: hideLineChart
                    ? "none"
                    : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                }}
              >
                {finalDataKeys.map(
                  (key, i) =>
                    !["market", "industry"].includes(key) && (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center  align-self-baseline align-self-sm-auto w-100"
                        key={`${i}_${key}`}
                      >
                        <h1
                          className="card-title fw-bolder"
                          style={{ color: submissionRatioChartColors[i] }}
                        >
                          {`${
                            metricsData[i]?.totalSubmission
                              ? metricsData[i].totalSubmission
                              : 0
                          }`}
                        </h1>
                        <h6 className="metrics-label text-center">
                          {getCustomKeyName(key, "submission")}
                        </h6>
                      </div>
                    )
                )}
              </div>
            </Col>
          </Row>
        </Col>
        {!hideLineChart && (
          <Col md={8}>
            <Row className="gap-2 gap-md-0 h-100">
              {["success", "submission"].map((line, i) => {
                return (
                  <Col key={`${i}_${line}`} className="col-12 col-md-6">
                    <div
                      className="card-layout h-100 w-100 m-0"
                      style={{
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                      }}
                    >
                      <LineRecharts
                        lineData={lineData[i]}
                        datakeys={
                          !isInternalUser &&
                          lineData[i]?.[0]?.ratioType === "submission"
                            ? ["company"]
                            : finalDataKeys
                        }
                        dataLabels={getDataLabels(i)}
                        isExpandAllowed={true}
                        openModal={openModal}
                        selectedLineIndex={i}
                        setSelectedLineIndex={setSelectedLineIndex}
                        isYAxisContainsPercentData={
                          lineData[i]?.[0]?.ratioType === "success"
                        }
                        yAxisLabelName={
                          lineData[i]?.[0]?.ratioType === "success"
                            ? "Success"
                            : "Submissions"
                        }
                        isSmall={true}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        )}
      </Row>
      <ModalBox
        title={
          lineData[selectedLineIndex]?.[0]?.ratioType === "success"
            ? "Success"
            : "Submissions"
        }
        show={showModal}
        onHide={closeModal}
        content={
          <LineRecharts
            lineData={lineData[selectedLineIndex]}
            datakeys={
              !isInternalUser &&
              lineData[selectedLineIndex]?.[0]?.ratioType === "submission"
                ? ["company"]
                : finalDataKeys
            }
            dataLabels={getDataLabels(selectedLineIndex)}
            isOpenModal={showModal}
            selectedLineIndex={selectedLineIndex}
            isYAxisContainsPercentData={
              lineData[selectedLineIndex]?.[0]?.ratioType === "success"
            }
            yAxisLabelName={
              lineData[selectedLineIndex]?.[0]?.ratioType === "success"
                ? "Success"
                : "Submissions"
            }
          />
        }
      />
    </div>
  );
};

export default SuccessKpiStats;
