import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import "../../pages/Users/Users.scss";
import SelectUI from "../Select/SelectUI";
import { selectOptionConverter } from "../../helpers/ReusableFunctions";
import { actionOption, reportOption } from "../../helpers/Constant";

const EditPermissionCard = ({
  handleCloseEditModal,
  selectedRow,
  setReportData,
}) => {
  const [formData, setFormData] = useState({
    id: selectedRow.id,
    user: selectedRow.user,
    action: actionOption[0],
    reports: selectedRow.reports,
  });

  const selectedFormValue = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      reports: e?.map((val) => val.value),
    }));
  };

  const editRecord = (id, updatedRecord) => {
    setReportData((prevRecords) => {
      // Find the index of the record with the specified ID
      const recordIndex = prevRecords.findIndex((record) => record.id === id);

      if (recordIndex !== -1) {
        // Make a copy of the original record
        const updatedRecords = [...prevRecords];

        // Update the desired properties of the record
        updatedRecords[recordIndex] = { ...updatedRecord };

        return updatedRecords;
      }

      return prevRecords;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    editRecord(selectedRow.id, formData);
    setFormData({
      id: "",
      user: "",
      action: "",
      reports: [],
    });
    handleCloseEditModal();
  };

  return (
    <Row>
      <Col md={12}>
        <div className="permission-card">
          <form>
            <Row>
              <Col md={12}>
                <div className="user-label">Choose User Type</div>
                <SelectUI
                  options={selectOptionConverter([selectedRow.user])}
                  className="add-class-select"
                  defaultValue={true}
                  selectedRow={selectedRow}
                  isDisabled={true}
                />
              </Col>

              <Col md={12}>
                <div className="user-label">Choose Report</div>
                <SelectUI
                  options={selectOptionConverter(reportOption)}
                  className="add-class-select"
                  value={selectOptionConverter(selectedRow.reports)}
                  isMulti={true}
                  selectedValue={selectedFormValue}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="user-card-button">
                <div className="action-button mb-4" onClick={handleSubmit}>
                  <div className="action-text">Save</div>
                </div>
                <div
                  className="action-button-outline"
                  onClick={handleCloseEditModal}
                >
                  <div className="action-text">Cancel</div>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Col>
    </Row>
  );
};

export default EditPermissionCard;
