import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import { Card } from "react-bootstrap";

import "./Leaderboard.scss";

import PageTitle from "components/PageTitle/PageTitle";
import CustomFilter from "components/Reports/CustomFilter";
import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";
import {
  getCandidatePipelineLeaderboardData,
  getRecentlyActiveCandidatesLeaderboardData,
  getSuccessKpiLeaderboardData,
  getTimeToHireLeaderboardData,
} from "services/FetchApiData";
import {
  convertGridColumnMenu,
  convertStageCodeToName,
  customStageColumnSorting,
  formatDate,
  getCurrentPageAllFiltersFromFilterStore,
} from "helpers/ReusableFunctions";
import { PAGES } from "helpers/Constant";
import FilterNav from "components/FilterNav/FilterNav";
import SelectUI from "components/Select/SelectUI";
import TopFilters from "pages/PipelineReport/components/TopFilters";
import { AppContext } from "context/AppContext";
import { useTranslation } from "react-i18next";

const Leaderboard = () => {
  const { t } = useTranslation();
  const NotCallApi = !["/successkpireport/leaderboard"].includes(
    window.location.pathname
  );
  const CMBM_toggle_options = [
    {
      label: "Candidate Manager",
      value: "cm",
    },
    {
      label: "Business Manager",
      value: "bm",
    },
  ];

  const { language } = useContext(UserContext);
  const { isInternalUser, lastAppliedFilterstore, dropdownData } =
    useContext(FilterContext);
  const { candidatesGroupedByBM, candidatesGroupedByCM } =
    useContext(AppContext);

  const [reportLeaderboardData, setReportLeaderboardData] = useState([]);
  const [ReportData, setReportData] = useState([]);
  const [CandidatesReportData, setCandidatesReportData] = useState([]);
  const [isLoadLeaderboardData, setIsLoadLeaderboardData] = useState(false);
  const [isCMToggled, setIsCMToggled] = useState(true);
  const [selectedCMBMValue, setSelectedCMBMValue] = useState([]);

  const location = useLocation();
  const apiRef = useGridApiRef();
  const candidateListTableDivRef = useRef(null);

  const navigatedHereFrom = location.pathname.split("/")[1];

  const pageTitle = useMemo(() => {
    return location.state?.currentPageTitle;
  }, [location.state?.currentPageTitle]);

  const backPageTitle = useMemo(() => {
    return location.state?.backToPageTitle;
  }, [location.state?.backToPageTitle]);

  const cmbmToggleHandler = (value) => {
    setIsCMToggled((prev) => !prev);
  };

  const cmbmChangeHandler = (value) => {
    setSelectedCMBMValue(value);
  };

  function isValidCmId(id) {
    return dropdownData.CandidateManagerOptions.map(
      (opt) => opt.value
    ).includes(id);
  }

  function isValidBmId(id) {
    return dropdownData.BusinessManagerOptions.map((opt) => opt.value).includes(
      id
    );
  }

  let { cm_ids: candidate_managers_id, bm_ids: business_managers_id } =
    location.state?.leaderboard_access_ids;

  //handling invalid cmbm_id
  candidate_managers_id = candidate_managers_id.filter((id) => isValidCmId(id));
  business_managers_id = business_managers_id.filter((id) => isValidBmId(id));

  const NullDataHoldsCMIds = useMemo(() => {
    if (isEmpty(lastAppliedFilterstore.CandidateManagers)) {
      return [];
    }
    return lastAppliedFilterstore.CandidateManagers.filter(
      (cm) =>
        !location.state?.leaderboard_access_ids?.cm_ids?.includes(cm.value) &&
        cm.value !== "*"
    ).map((cm1) => cm1.value);
  }, [
    lastAppliedFilterstore.CandidateManagers,
    location.state?.leaderboard_access_ids?.cm_ids,
  ]);

  const NullDataHoldsBMIds = useMemo(() => {
    if (isEmpty(lastAppliedFilterstore.BusinessManagers)) {
      return [];
    }
    return lastAppliedFilterstore.BusinessManagers.filter(
      (cm) =>
        !location.state?.leaderboard_access_ids?.bm_ids?.includes(cm.value) &&
        cm.value !== "*"
    ).map((cm1) => cm1.value);
  }, [
    lastAppliedFilterstore.BusinessManagers,
    location.state?.leaderboard_access_ids?.bm_ids,
  ]);

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const initialSortModel = useMemo(() => {
    if (navigatedHereFrom === "successkpireport") {
      return { field: "percent_success", sort: "desc" };
    } else if (navigatedHereFrom === "timetohirereport") {
      return { field: "average_time", sort: "asc" };
    } else {
      return { field: "total_no", sort: "desc" };
    }
  }, [navigatedHereFrom]);

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: t("performanceReportText.id"),
        minWidth: 200,

        flex: 1,
      },
      {
        field: "manager_name",
        headerName: isCMToggled ? "Candidate Manager" : "Business Manager",
        minWidth: 350,
        sortable: true,
        pinnable: true,
        filterOperators: filterOperators,

        flex: 1,
      },
      ...(navigatedHereFrom === "successkpireport"
        ? [
            {
              field: "percent_success",
              headerName: "Success Percentage",
              minWidth: 300,
              sortable: true,
              pinnable: true,
              filterOperators: filterOperators,
              renderCell: ({ value }) => {
                return value + " %";
              },

              flex: 1,
            },
          ]
        : navigatedHereFrom === "timetohirereport"
        ? [
            {
              field: "average_time",
              headerName: "Average Time",
              minWidth: 300,
              sortable: true,
              pinnable: true,
              filterOperators: filterOperators,
              renderCell: ({ value }) => {
                return value + " days";
              },
              resizable: true,
              flex: 1,
            },
          ]
        : []),
      {
        field: "total_no",
        headerName: "Total Number",
        minWidth: 300,
        sortable: true,
        pinnable: true,
        filterOperators: filterOperators,

        flex: 1,
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCMToggled, language, navigatedHereFrom]
  );

  const japaneseName = [
    {
      field: "kanji_last",
      headerName: t("performanceReportText.lastNameJ"),
      minWidth: 200,
      sortable: true,
      pinnable: true,
      filterOperators: filterOperators,
      flex: 1,
    },
    {
      field: "kanji_first",
      headerName: t("performanceReportText.firstNameJ"),
      minWidth: 200,
      sortable: true,
      pinnable: true,
      filterOperators: filterOperators,
      flex: 1,
    },
  ];

  const englishName = [
    {
      field: "first_name",
      headerName: t("performanceReportText.firstName"),
      minWidth: 200,
      sortable: true,
      pinnable: true,
      filterOperators: filterOperators,
      flex: 1,
    },
    {
      field: "last_name",
      headerName: t("performanceReportText.lastName"),
      minWidth: 200,
      sortable: true,
      pinnable: true,
      filterOperators: filterOperators,
      flex: 1,
    },
  ];

  const detailedColumns = useMemo(
    () => [
      {
        field: "id",
        headerName: t("performanceReportText.id"),
        minWidth: 200,
        flex: 1,
      },
      {
        field: "app_position_name",
        headerName: "Position",
        minWidth: 200,
        sortable: true,
        pinnable: true,
        filterOperators: filterOperators,
        flex: 1,
      },
      ...(language === "ja" ? japaneseName : englishName),
      {
        field: "current_stage",
        type: "string",
        headerName: "Stage",
        minWidth: 200,
        sortable: true,
        pinnable: true,
        filterOperators: filterOperators,
        valueGetter: ({ row }) => {
          return convertStageCodeToName(
            row?.current_stage,
            row?.app_position?.pitch_response.response,
            "New Candidate"
          );
        },
        sortComparator: customStageColumnSorting,
        flex: 1,
      },
      {
        field: "last_activity_date",
        headerName: "Date of Last Activity",
        minWidth: 200,
        sortable: true,
        pinnableColumn: true,
        filterOperators: filterOperators,
        renderCell: ({ value }) => {
          return formatDate(value);
        },
        flex: 1,
      },
      {
        field: "current_employer",
        headerName: t("performanceReportText.employer"),
        minWidth: 200,
        sortable: true,
        pinnable: true,
        filterOperators: filterOperators,
        flex: 1,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const initialHiddenColumns = {
    id: false,
  };

  const columnsWithOperators = (columns) =>
    columns.map((col) => {
      if (col.type === "number") {
        return {
          ...col,
          filterOperators: [
            ...getGridNumericOperators()
              .filter((operator) => {
                if (operator.value === "=") operator.label = "equals";
                if (operator.value === ">") operator.label = "greater than";
                if (operator.value === "<") operator.label = "less than";
                return (
                  operator.value === ">" ||
                  operator.value === "<" ||
                  operator.value === "="
                );
              })
              .map((operator) => ({
                ...operator,
                InputComponent: CustomFilter,
              })),
          ],
        };
      }

      const customFilterOperators = getGridStringOperators().map(
        (operator) => ({
          ...operator,
          InputComponent: CustomFilter,
        })
      );

      return {
        ...col,
        filterOperators: customFilterOperators,
      };
    });

  const getCurrentCandidateManagersDetail = () => {
    const cm_names = lastAppliedFilterstore.CandidateManagers?.filter(
      (item) => candidate_managers_id.includes(item.value) && item.value !== "*"
    ).map((cm) => cm?.value);

    const cm_names_objects = dropdownData.CandidateManagerOptions.filter(
      (opt) => cm_names.includes(opt.value)
    );

    return cm_names.map((val) =>
      cm_names_objects.find((obj) => obj.value === val)
    );
  };

  const getCurrentBusinessManagersDetail = () => {
    const bm_names = lastAppliedFilterstore.BusinessManagers?.filter(
      (item) => business_managers_id.includes(item.value) && item.value !== "*"
    ).map((bm) => bm?.value);

    const bm_names_objects = dropdownData.BusinessManagerOptions.filter((opt) =>
      bm_names.includes(opt.value)
    );

    return bm_names.map((val) =>
      bm_names_objects.find((obj) => obj.value === val)
    );
  };

  const getListOfCandidateManagersDetail = () => {
    const cm_names_objects = dropdownData.CandidateManagerOptions?.filter(
      (item) => candidate_managers_id.includes(item.value)
    );

    return candidate_managers_id.map((val) =>
      cm_names_objects.find((item) => item.value === val)
    );
  };

  const getListOfBusinessManagersDetail = () => {
    const bm_names_objects = dropdownData.BusinessManagerOptions?.filter(
      (item) => business_managers_id.includes(item.value)
    );

    return business_managers_id.map((val) =>
      bm_names_objects.find((item) => item.value === val)
    );
  };

  const makeReportsData = (reportLeaderboardData) => {
    const ReportData = reportLeaderboardData?.map((obj) => ({
      id: obj.id,
      total_no: obj.total,
      percent_success: obj?.percent_success,
      // candidate_manager: obj.candidate_manager,
      // business_manager: obj.business_manager,
      manager_name: obj?.manager_name,
      manager_id: obj?.manager_id,
      average_time: obj?.average_time,
    }));

    setReportData(ReportData);
  };

  const makeCandidatesReportsData = (data) => {
    const ReportData =
      data?.map((user, index) => ({
        id: index + 1,
        app_position_name: user.app_position?.position_name,
        current_stage: user.app_position?.current_stage,
        last_activity_date: new Date(user.last_activity_date),
        ...user,
      })) ?? [];
    setCandidatesReportData(ReportData);
  };

  function extractInfo(args) {
    const { res, index, cmbmName, cmbmId } = args;
    // let isCM = currentCmBmValue.value === "cm" ? true : false;
    let resultObj = {};
    let commonResultObj = {
      id: index + 1,
      // candidate_manager: isCM ? cmbmName : "",
      // business_manager: !isCM ? cmbmName : "",
      manager_name: cmbmName,
      manager_id: cmbmId,
    };
    if (["successkpireport", "timetohirereport"].includes(navigatedHereFrom)) {
      let response_data = {};
      if (navigatedHereFrom === "successkpireport") {
        if (isEmpty(res)) {
          resultObj = {
            ...commonResultObj,
            percent_success: 0,
            total: 0,
          };
        } else {
          response_data = res.data.data?.reports?.success_kpi;

          const esaiUserIntervalData = response_data?.filter(
            (item) => item.benchmark === "esai_user"
          )[0].intervals;

          const esaiUserLastIntervalData =
            esaiUserIntervalData[esaiUserIntervalData.length - 1];

          resultObj = {
            ...commonResultObj,
            percent_success:
              esaiUserLastIntervalData?.data?.percent_success ?? 0,
            total: esaiUserLastIntervalData?.data?.total ?? 0,
          };
        }
      }
      if (navigatedHereFrom === "timetohirereport") {
        if (isEmpty(res)) {
          resultObj = {
            ...commonResultObj,
            total: 0,
            average_time: 0,
          };
        } else {
          response_data = NotCallApi
            ? res
            : res.data.data?.reports?.time_to_hire_candidates_list;

          const time_diff_arr = response_data?.map(
            (user) => user?.time_diff?.days ?? 0
          );

          const averageTime =
            Array.isArray(time_diff_arr) && time_diff_arr.length > 0
              ? time_diff_arr.reduce((total, val) => total + Number(val)) /
                time_diff_arr?.length
              : 0;

          resultObj = {
            ...commonResultObj,
            total: response_data?.length ?? 0,
            average_time: Math.round(averageTime),
          };
        }
      }
    } else {
      if (isEmpty(res)) {
        resultObj = {
          ...commonResultObj,
          total: 0,
        };
      } else {
        let response_data = [];
        if (navigatedHereFrom === "candidatepipelinereport") {
          response_data = NotCallApi
            ? res
            : res.data.data.reports.active_pipeline?.data;
        }
        if (navigatedHereFrom === "recentlyactivecandidatesreport") {
          response_data = NotCallApi
            ? res
            : res.data.data.reports?.recently_active_candidates;
        }

        resultObj = {
          ...commonResultObj,
          total: response_data?.length ?? 0,
        };
      }
    }

    return resultObj;
  }

  const fetchLeaderboardDataPerCMBM = (cmbm_id) => {
    let isCandidateManager = false;
    isCandidateManager = isCMToggled;

    let functionToCall = () => {};

    switch (navigatedHereFrom) {
      case "successkpireport":
        functionToCall = async () =>
          await getSuccessKpiLeaderboardData(
            getCurrentPageAllFiltersFromFilterStore(
              lastAppliedFilterstore,
              isInternalUser,
              PAGES.SUCCESS_KPI
            ),
            cmbm_id,
            isCandidateManager
          );

        break;

      case "recentlyactivecandidatesreport":
        functionToCall = async () =>
          await getRecentlyActiveCandidatesLeaderboardData(
            getCurrentPageAllFiltersFromFilterStore(
              lastAppliedFilterstore,
              isInternalUser,
              PAGES.RECENTLY_ACTIVE_CANDIDATES
            ),
            cmbm_id,
            isCandidateManager
          );

        break;

      case "timetohirereport":
        functionToCall = async () =>
          await getTimeToHireLeaderboardData(
            getCurrentPageAllFiltersFromFilterStore(
              lastAppliedFilterstore,
              isInternalUser,
              PAGES.TIME_TO_HIRE
            ),
            cmbm_id,
            isCandidateManager
          );

        break;

      case "candidatepipelinereport":
        functionToCall = async () =>
          await getCandidatePipelineLeaderboardData(
            getCurrentPageAllFiltersFromFilterStore(
              lastAppliedFilterstore,
              isInternalUser,
              PAGES.CANDIDATE_PIPELINE
            ),
            cmbm_id,
            isCandidateManager
          );

        break;

      default:
        return () => {};
    }

    try {
      const response = functionToCall();
      return response;
    } catch (error) {
      throw error;
    }
  };

  const fetchLeaderboardData = () => {
    const isCM = isCMToggled;

    setIsLoadLeaderboardData(true);
    if (
      isEmpty(
        lastAppliedFilterstore[isCM ? "CandidateManagers" : "BusinessManagers"]
      )
    ) {
      if (NotCallApi) {
        setIsLoadLeaderboardData(false);
        const preFinalRes = [
          ...(isCM ? candidate_managers_id : business_managers_id),
        ].map((id) =>
          isCM ? candidatesGroupedByCM[id] : candidatesGroupedByBM[id]
        );

        const finalRes =
          preFinalRes.length > 0
            ? preFinalRes.map((res, index) => {
                const cmbm = [
                  ...(isCM
                    ? getListOfCandidateManagersDetail()
                    : getListOfBusinessManagersDetail()),
                ][index];
                return extractInfo({
                  res,
                  index,
                  cmbmName: cmbm?.label,
                  cmbmId: cmbm?.value,
                });
              })
            : [];
        setReportLeaderboardData(finalRes);
      } else {
        const promiseArray = [
          ...(isCM ? candidate_managers_id : business_managers_id),
        ].map((cmbm_id) => fetchLeaderboardDataPerCMBM(cmbm_id));
        Promise.all(promiseArray)
          .then((responses) => {
            const finalRes = responses.map((res, index) => {
              const cmbm = [
                ...(isCM
                  ? getListOfCandidateManagersDetail()
                  : getListOfBusinessManagersDetail()),
              ][index];
              return extractInfo({
                res,
                index,
                cmbmName: cmbm?.label,
                cmbmId: cmbm?.value,
              });
            });
            setReportLeaderboardData(finalRes);
          })
          .catch((error) => console.log(error))
          .finally(() => {
            setIsLoadLeaderboardData(false);
          });
      }
    } else {
      if (NotCallApi) {
        setIsLoadLeaderboardData(false);
        const preFinalRes = [
          ...lastAppliedFilterstore[
            isCM ? "CandidateManagers" : "BusinessManagers"
          ]?.filter(
            (cmbmOpt) =>
              [
                ...(isCM ? candidate_managers_id : business_managers_id),
              ].includes(cmbmOpt.value) && cmbmOpt.value !== "*"
          ),
        ].map((cmbm_opt) =>
          isCM
            ? candidatesGroupedByCM[cmbm_opt.value]
            : candidatesGroupedByBM[cmbm_opt.value]
        );

        const finalResOfWithoutApiCall =
          preFinalRes.length > 0
            ? preFinalRes.map((res, index) => {
                const cmbm = [
                  ...(isCM
                    ? getCurrentCandidateManagersDetail()
                    : getCurrentBusinessManagersDetail()),
                ][index];
                return extractInfo({
                  res,
                  index,
                  cmbmName: cmbm?.label,
                  cmbmId: cmbm?.value,
                });
              })
            : [];

        const otherCMsData =
          [...(isCM ? NullDataHoldsCMIds : NullDataHoldsBMIds)].length > 0
            ? [...(isCM ? NullDataHoldsCMIds : NullDataHoldsBMIds)].map(
                (id, index) => {
                  const cmbm1 = dropdownData[
                    isCM ? "CandidateManagerOptions" : "BusinessManagerOptions"
                  ]?.find((cmbm) => cmbm.value === id);
                  return extractInfo({
                    res: {},
                    index: finalResOfWithoutApiCall.length + index,
                    cmbmName: cmbm1?.label,
                    cmbmId: cmbm1?.value,
                  });
                }
              )
            : [];
        setReportLeaderboardData(finalResOfWithoutApiCall.concat(otherCMsData));
      } else {
        const promiseArray = lastAppliedFilterstore[
          isCM ? "CandidateManagers" : "BusinessManagers"
        ]
          ?.filter(
            (cmbmOpt) =>
              [
                ...(isCM ? candidate_managers_id : business_managers_id),
              ].includes(cmbmOpt.value) && cmbmOpt.value !== "*"
          )
          .map((cmbmOpt) => fetchLeaderboardDataPerCMBM(cmbmOpt.value));

        Promise.all(promiseArray)
          .then((responses) => {
            const finalResOfWithApiCall = responses.map((res, index) => {
              const cmbm = [
                ...(isCM
                  ? getCurrentCandidateManagersDetail()
                  : getCurrentBusinessManagersDetail()),
              ][index];
              return extractInfo({
                res,
                index,
                cmbmName: cmbm?.label,
                cmbmId: cmbm?.value,
              });
            });

            const otherCMsData =
              [...(isCM ? NullDataHoldsCMIds : NullDataHoldsBMIds)].length > 0
                ? [...(isCM ? NullDataHoldsCMIds : NullDataHoldsBMIds)].map(
                    (id, index) => {
                      const cmbm1 = dropdownData[
                        isCM
                          ? "CandidateManagerOptions"
                          : "BusinessManagerOptions"
                      ]?.find((cmbm) => cmbm.value === id);
                      return extractInfo({
                        res: {},
                        index: finalResOfWithApiCall.length + index,
                        cmbmName: cmbm1?.label,
                        cmbmId: cmbm1?.value,
                      });
                    }
                  )
                : [];

            setReportLeaderboardData(
              finalResOfWithApiCall.concat(otherCMsData)
            );
          })
          .catch((error) => console.log(error))
          .finally(() => {
            setIsLoadLeaderboardData(false);
          });
      }
    }
  };

  useEffect(() => {
    makeReportsData(reportLeaderboardData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportLeaderboardData]);

  useEffect(() => {
    const allCandidatesData = isCMToggled
      ? candidatesGroupedByCM
      : candidatesGroupedByBM;

    if (isEmpty(selectedCMBMValue)) return;

    if (
      (isCMToggled && !isValidCmId(selectedCMBMValue?.value)) ||
      (!isCMToggled && !isValidBmId(selectedCMBMValue?.value))
    )
      return;

    const candidatesUnderManager = allCandidatesData[selectedCMBMValue.value];

    makeCandidatesReportsData(candidatesUnderManager);

    return () => setCandidatesReportData([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCMBMValue]);

  useEffect(() => {
    fetchLeaderboardData();
    setSelectedCMBMValue([]);

    return () => {
      setReportData([]);
      setReportLeaderboardData([]);
      setSelectedCMBMValue([]);
      setCandidatesReportData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dropdownData.CandidateManagerOptions,
    dropdownData.BusinessManagerOptions,
    isCMToggled,
  ]);

  return (
    <>
      <PageTitle
        title={pageTitle}
        back={backPageTitle}
        navigateBack={navigatedHereFrom}
      />

      <FilterNav apiRef={apiRef} />
      <TopFilters />

      <div className="card mt-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-1">
            <h5 className="flex-shrink-1 ">{`List of ${
              isCMToggled ? "Candidate" : "Business"
            } Manager`}</h5>
            <Card
              className="shadow-3-strong mb-1 p-2 ms-auto"
              style={{
                minWidth: "180px",
              }}
            >
              <SelectUI
                options={CMBM_toggle_options}
                value={CMBM_toggle_options.find((obj) => {
                  let compareWith = isCMToggled ? "cm" : "bm";
                  return obj.value === compareWith;
                })}
                selectedValue={cmbmToggleHandler}
              />
            </Card>
          </div>
          <div
            className="cstm-mui-datagrid mx-auto"
            style={{ maxHeight: 500, height: 450, width: "100%" }}
          >
            <DataGridPro
              loading={isLoadLeaderboardData}
              rows={ReportData.length > 0 ? ReportData : []}
              columns={columnsWithOperators(columns)}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
              }}
              initialState={{
                ...columns.initialState,
                sorting: {
                  sortModel: [initialSortModel],
                },
                columns: {
                  ...columns.initialState?.columns,
                  columnVisibilityModel: initialHiddenColumns,
                },
              }}
              onRowClick={(params) => {
                const {
                  row: { manager_id, manager_name },
                } = params;
                setSelectedCMBMValue({
                  label: manager_name,
                  value: manager_id,
                });

                // FIXME: Chrome not supporting this functionality
                candidateListTableDivRef?.current?.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }}
              hideFooter
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              rowsLoadingMode={NotCallApi ? "client" : "server"}
            />
          </div>
        </div>
      </div>

      <div className="card mt-4" ref={candidateListTableDivRef}>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-1">
            <h5 className="flex-shrink-1">{`List of Candidates`}</h5>
            <Card
              className="mb-1 shadow-3-strong p-2 ms-auto"
              style={{
                minWidth: "180px",
              }}
            >
              <SelectUI
                options={
                  isCMToggled
                    ? isEmpty(lastAppliedFilterstore["CandidateManagers"])
                      ? getListOfCandidateManagersDetail()
                      : getCurrentCandidateManagersDetail()
                    : isEmpty(lastAppliedFilterstore["BusinessManagers"])
                    ? getListOfBusinessManagersDetail()
                    : getCurrentBusinessManagersDetail()
                }
                value={selectedCMBMValue}
                selectedValue={cmbmChangeHandler}
                placeholder={`Select ${
                  isCMToggled ? "Candidate Manager" : "Business Manager"
                }`}
                isDisabled={!ReportData.length}
              />
            </Card>
          </div>
          <div
            className="cstm-mui-datagrid mx-auto"
            style={{ maxHeight: 500, height: 450, width: "100%" }}
          >
            <DataGridPro
              rows={CandidatesReportData.length > 0 ? CandidatesReportData : []}
              columns={columnsWithOperators(detailedColumns)}
              slotProps={{
                filterPanel: {
                  sx: { maxWidth: "calc(90vw - 24px)" },
                },
              }}
              initialState={{
                ...columns.initialState,
                columns: {
                  ...columns.initialState?.columns,
                  columnVisibilityModel: initialHiddenColumns,
                },
              }}
              hideFooter
              localeText={convertGridColumnMenu(language)}
              apiRef={apiRef}
              rowsLoadingMode={"client"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
