import React from "react";
import { Col, Row } from "react-bootstrap";

import "./Settings.scss";
import ExcelSvg from "../../assets/svg/file-excel-solid.svg";

const Notifications = () => {
  const messagesPayload = [
    // {
    //   id: 1,
    //   author: "John Doe",
    //   content: [
    //     {
    //       type: "profile",
    //       message: "Your profile update was successful.",
    //       timestamp: "2023-07-21",
    //       isRead: false,
    //     },
    //     {
    //       type: "profile",
    //       message: "Your profile update was successful.",
    //       timestamp: "2023-07-21",
    //       isRead: false,
    //     },
    //   ],
    // },
    // {
    //   id: 2,
    //   author: "Jane Smith",
    //   content: [
    //     {
    //       type: "report",
    //       message: "The quarterly performance report needs your approval.",
    //       timestamp: "2023-07-20",
    //       isRead: true,
    //     },
    //     {
    //       type: "profile",
    //       message: "Your password has been changed successfully.",
    //       timestamp: "2023-07-20",
    //       isRead: true,
    //     },
    //   ],
    // },
    // {
    //   id: 3,
    //   author: "Alex Johnson",
    //   content: [
    //     {
    //       type: "report",
    //       message: "Please find attached the financial year-end report.",
    //       timestamp: "2023-07-19",
    //       isRead: false,
    //     },
    //     {
    //       type: "report",
    //       message: "Please find attached the financial year-end report.",
    //       timestamp: "2023-07-19",
    //       isRead: false,
    //     },
    //   ],
    // },
    // {
    //   id: 4,
    //   author: "Emily Williams",
    //   content: [
    //     {
    //       type: "profile",
    //       message: "Your subscription has been renewed.",
    //       timestamp: "2023-07-18",
    //       isRead: true,
    //     },
    //   ],
    // },
    // {
    //   id: 5,
    //   author: "Michael Brown",
    //   content: [
    //     {
    //       type: "report",
    //       message:
    //         "Requesting your feedback on the customer satisfaction report.",
    //       timestamp: "2023-07-17",
    //       isRead: true,
    //     },
    //   ],
    // },
    // {
    //   id: 6,
    //   author: "Sophia Lee",
    //   content: [
    //     {
    //       type: "report",
    //       message: "Urgent: Security audit report needs immediate attention.",
    //       timestamp: "2023-07-16",
    //       isRead: false,
    //     },
    //     {
    //       type: "profile",
    //       message: "Your account email has been updated.",
    //       timestamp: "2023-07-15",
    //       isRead: true,
    //     },
    //   ],
    // },
    // Add more user messages as needed
  ];

  return (
    <div>
      <Row>
        <Col md={1}></Col>
        <Col md={5} className="">
          <div
            className="card heading-content mt-4"
            style={{ minHeight: "300px" }}
          >
            <h4 className="p-3 mb-3">Profile Notification</h4>

            {messagesPayload.length > 0 ? (
              messagesPayload.map((message) =>
                message.content.map((content, i) => (
                  <div className="text-end">
                    {content.type === "profile" && (
                      <div className="card p-4 m-2 flex-row justify-content-between mb-3">
                        <div className="d-flex" key={i}>
                          <img
                            // src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"
                            alt="profile"
                            height={32}
                            width={32}
                            className="profile-avatar"
                          />

                          <div className="ms-2 p-1 align-self-end">
                            {" "}
                            {content.message}
                          </div>
                        </div>
                        <div className="text-end date-text p-1">
                          {content.timestamp}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )
            ) : (
              <div className="ms-3 text-muted">There are no notifications.</div>
            )}
          </div>
        </Col>
        <Col md={5}>
          <div
            className="card heading-content mt-4"
            style={{ minHeight: "300px" }}
          >
            <h4 className="p-3 mb-3">Reports Notification</h4>

            {messagesPayload.length > 0 ? (
              messagesPayload.map((message) =>
                message.content.map((content, i) => (
                  <div>
                    {content.type !== "profile" && (
                      <div className="card p-4 m-2 flex-row justify-content-between mb-3">
                        <div className="d-flex" key={i}>
                          <img src={ExcelSvg} alt="profile" className="p-1" />
                          <div className="ms-2 p-1 align-self-end">
                            {content.message}
                          </div>
                        </div>
                        <div className="text-end date-text p-1">
                          {content.timestamp}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )
            ) : (
              <div className="ms-3 text-muted">There are no notifications.</div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Notifications;
