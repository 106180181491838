import React, { useContext, useMemo } from "react";
import moment from "moment";

import SelectUI from "components/Select/SelectUI";
import RadioOption from "./RadioOption";
import { FilterContext } from "context/FilterContext";
import { UserContext } from "context/UserContext";
import { useTranslation } from "react-i18next";

const CompareWithSelect = ({
  compareRadioSelected,
  onCompareRadioSelectChange,
  selectedCompareWithValue,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const { filterStore } = useContext(FilterContext);
  const { language } = useContext(UserContext);

  const startDateFormatOptionLabel = ({ value, label }, { context }) => {
    if (context === "value") {
      if (value === 0 || !value || filterStore.count === 0) {
        return (
          <span className="compare-with-selected-value">
            {t("timelineText.none")}
          </span>
        );
      }
      const unitLabel = filterStore.unit
        ? t(`timelineText.${filterStore.unit}`, { lng: "en" }) +
          (filterStore.count > 1 ? "s" : "")
        : t("timelineText.quarter", { lng: "en" }) +
          (filterStore.count > 1 ? "s" : "");
      if (value === "custom number") {
        return (
          <span className="compare-with-selected-value">
            {language === "en"
              ? `${t("timelineText.compareWith")} ${
                  filterStore.count
                } ${unitLabel}`
              : `${filterStore.count}${t(
                  `timelineText.${filterStore.unit}`
                )}${t("timelineText.compareWith")}`}
          </span>
        );
      }

      return (
        <span className="compare-with-selected-value">
          {language === "en"
            ? `${t("timelineText.compareWith")} ${value} ${unitLabel}`
            : `${filterStore.count}${t(`timelineText.${filterStore.unit}`)}${t(
                "timelineText.compareWith"
              )}`}
        </span>
      );
    } else if (context === "menu") {
      return <>{label}</>;
    }
  };

  const compareWithRadioOptions = useMemo(() => {
    const start = moment(filterStore.startDate);
    const end = moment(filterStore.endDate);
    const difference = end.diff(start, "days") + 1; // difference in days to display in custom date range

    const isMonthDisabled = end.diff(start, "days") > 30;
    const isQuarterDisabled = end.diff(start, "days") > 91;

    const isLeapYear = end.diff(start, "years") === 0 && end.isLeapYear();
    const isYearDisabled = isLeapYear
      ? end.diff(start, "days") >= 366
      : end.diff(start, "days") >= 365;

    // disable custom date range
    let isPeriodDisabled = false;
    const monthStart = start.date() === 1;
    const monthEnd =
      moment(filterStore.endDate).endOf("month").diff(end, "days") === 0;

    const mainQuarterStart = monthStart && [0, 3, 6, 9].includes(start.month());
    const mainQuarterEnd = monthEnd && [2, 5, 8, 11].includes(end.month());

    // const yearStart =
    //   moment(filterStore.startDate).startOf("year").diff(start, "days") === 0;
    // const yearEnd =
    //   moment(filterStore.endDate).endOf("year").diff(end, "days") === 0;

    const yearStart = moment(filterStore.startDate).startOf("year");
    const yearEnd = moment(filterStore.endDate).endOf("year");
    const isYearStart = yearStart.diff(start, "days") === 0;
    const isYearEnd = yearEnd.diff(end, "days") === 0;

    const withinMonth = difference < 32;
    const withinQuarter = difference < 93 && difference > 30;
    const withinYear = difference < 367 && difference > 92;

    // const updateRadioContext = (unit) => {
    // setCompareRadioSelected(unit);
    // setFilterStore((prev) => ({ ...prev, unit }));
    // };

    if (monthStart && monthEnd && start.month() === end.month()) {
      isPeriodDisabled = true;
    } else if (end.diff(start, "months") === 2) {
      if ((mainQuarterStart && mainQuarterEnd) || (monthStart && monthEnd)) {
        isPeriodDisabled = true;
      }
    } else if (isYearStart && isYearEnd && start.year() === end.year()) {
      isPeriodDisabled = true;
    } else {
      isPeriodDisabled = false;
    }

    if (
      ((monthStart && monthEnd) || withinMonth) &&
      start.month() === end.month()
    ) {
      // updateRadioContext("month");
    } else if (
      (mainQuarterStart && mainQuarterEnd) ||
      (monthStart && monthEnd) ||
      withinQuarter
    ) {
      // updateRadioContext("quarter");
    } else if (
      ((isYearStart && isYearEnd) || withinYear) &&
      start.year() === end.year()
    ) {
      // updateRadioContext("year");
    } else {
      isPeriodDisabled = false;
      // updateRadioContext("custom");
    }

    return [
      {
        label: t("timelineText.month"),
        value: "month",
        isDisabled: isMonthDisabled,
      },
      {
        label: t("timelineText.quarter"),
        value: "quarter",
        isDisabled: isQuarterDisabled,
      },
      {
        label: t("timelineText.year"),
        value: "year",
        isDisabled: isYearDisabled,
      },
      {
        label: (
          <CustomRangeLabel
            difference={difference}
            isPeriodDisabled={isPeriodDisabled}
          />
        ),
        value: "custom",
        isDisabled: isPeriodDisabled,
      },
    ];
  }, [t, filterStore.endDate, filterStore.startDate]);

  // comaprision end date select options
  const compareWithOptions = useMemo(() => {
    const optionValues = [1, 2, 3, 4];

    const initialOptions = optionValues.map((val) => ({
      value: val,
      label:
        language === "en"
          ? `${val} ${t("timelineText.quarter")}${val > 1 ? "s" : ""}`
          : `${val}${t("timelineText.quarter")}`,
    }));

    let dynamicOptions = initialOptions;

    switch (compareRadioSelected) {
      case "month":
        dynamicOptions = optionValues.map((val) => ({
          value: val,
          label:
            language === "en"
              ? `${val} ${t("timelineText.month")}${val > 1 ? "s" : ""}`
              : `${val}${t("timelineText.month")}`,
        }));
        break;
      case "quarter":
        dynamicOptions = optionValues.map((val) => ({
          value: val,
          label:
            language === "en"
              ? `${val} ${t("timelineText.quarter")}${val > 1 ? "s" : ""}`
              : `${val}${t("timelineText.quarter")}`,
        }));
        break;
      case "year":
        dynamicOptions = optionValues.map((val) => ({
          value: val,
          label:
            language === "en"
              ? `${val} ${t("timelineText.year")}${val > 1 ? "s" : ""}`
              : `${val}${t("timelineText.year")}`,
        }));
        break;
      case "custom":
        dynamicOptions = optionValues.map((val) => ({
          value: val,
          label:
            language === "en"
              ? `${val} ${t("timelineText.custom")}${val > 1 ? "s" : ""}`
              : `${val}${t("timelineText.custom")}`,
        }));
        break;
      default:
        dynamicOptions = initialOptions;
        break;
    }

    return [
      {
        value: 0,
        label: t("timelineText.none"),
      },
      ...dynamicOptions,
      {
        value: "custom number",
        label: t("timelineText.enterNumber"),
      },
      {
        value: "compareWithRadio",
        label: (
          <RadioOption
            selected={compareRadioSelected}
            setSelected={onCompareRadioSelectChange}
            compareWithRadioOptions={compareWithRadioOptions}
          />
        ),
        isDisabled: true,
      },
    ];
  }, [
    t,
    compareRadioSelected,
    language,
    onCompareRadioSelectChange,
    compareWithRadioOptions,
  ]);

  const getInheritValue = () => {
    const index = compareWithOptions.findIndex((option) => {
      return option.value === filterStore.count;
    });
    return index < 0 ? 5 : index;
  };

  return (
    <div className="compare-with-select">
      <SelectUI
        options={compareWithOptions}
        placeholder={"Compare with"}
        selectedValue={selectedCompareWithValue}
        formatOptionLabel={startDateFormatOptionLabel}
        defaultValue={true}
        value={
          filterStore.count === 0
            ? compareWithOptions[0]
            : compareWithOptions[getInheritValue()]
        }
        inheritValue={getInheritValue()}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default CompareWithSelect;

const CustomRangeLabel = ({ isPeriodDisabled, difference }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>{t("timelineText.customRange")}</div>
      <div>{!isPeriodDisabled ? `(${difference} days)` : ""}</div>
    </>
  );
};
