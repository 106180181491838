import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AccountSettings, Alert } from "@aws-amplify/ui-react";

import "./Settings.scss";

const LoginAndSecurity = ({ reRenderComponent = () => {} }) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleSuccess = () => {
    reRenderComponent();
    setShowAlert(true);
    const timeInterval = setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    return () => clearInterval(timeInterval);
  };

  const components = {
    CurrentPasswordField: (props) => {
      return (
        <AccountSettings.ChangePassword.CurrentPasswordField
          {...props}
          label="Old Password"
        />
      );
    },
    NewPasswordField: (props) => {
      return (
        <AccountSettings.ChangePassword.NewPasswordField
          {...props}
          label="New Password"
        />
      );
    },
    ConfirmPasswordField: (props) => {
      return (
        <AccountSettings.ChangePassword.ConfirmPasswordField
          {...props}
          label="Re-enter Password"
        />
      );
    },
    SubmitButton: (props) => {
      const { isDisabled, ...rest } = props;

      return (
        <div className="d-flex mt-4">
          <button
            type="submit"
            className="action-button"
            {...rest}
            disabled={isDisabled}>
            <div className="action-text">Change Password</div>
          </button>
        </div>
      );
    },
  };

  return (
    <Row>
      <Col md={3}></Col>
      <Col md={6} className="mt-4 card p-4">
        <h4>Change Password</h4>

        {showAlert && (
          <Alert
            variation="success"
            isDismissible={true}
            hasIcon={true}
            heading="">
            Password is successfully changed!
          </Alert>
        )}
        <div className="mt-3 mb-2">
          Enter the password associated with your account and re-enter the
          password for confirmation.
        </div>

        <AccountSettings.ChangePassword
          onSuccess={handleSuccess}
          components={components}
        />
      </Col>
      <Col md={3}></Col>
    </Row>
  );
};

export default LoginAndSecurity;
