import moment from "moment";

const DateLabel = ({
  text,
  date1,
  date2,
  displayOnlyMonth,
  displayBothDate,
}) => {
  return (
    <div className="d-flex justify-content-between">
      {text && <span>{text}</span>}
      {displayOnlyMonth ? (
        <span className="text-muted ps-2">
          {moment(date1).format("MMM YYYY")}
        </span>
      ) : displayBothDate ? (
        <span>
          <span className="text-muted ps-2">
            {moment(date1).format("D MMM")}
          </span>
          {" - "}
          <span className="text-muted">
            {moment(date2).format("D MMM YYYY")}
          </span>
        </span>
      ) : (
        <span className="text-muted ps-2">
          {moment(date1).format("D MMM YYYY")}
        </span>
      )}
    </div>
  );
};

export default DateLabel;
