import React from "react";
import "./CircleLabel.scss";
import { capitalizeLetter, nameConvention } from "helpers/ReusableFunctions";

const CircleLabel = ({ data, colors, pieChartNameConvention }) => {
  return (
    <div className="d-flex gap-2 justify-content-center flex-wrap">
      {data?.map((entry, index) => {
        return (
          <div
            className="d-flex justify-content-center align-items-center gap-2"
            key={index}>
            <div
              className="circle"
              style={{ backgroundColor: colors[index] }}></div>
            {capitalizeLetter(
              pieChartNameConvention?.length
                ? nameConvention(pieChartNameConvention, entry)?.value
                : entry.name
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CircleLabel;
