import React, { useCallback, useState } from "react";
import { MDBSwitch } from "mdb-react-ui-kit";

import "./Reports.scss";

import ListOfReports from "components/Reports/ListOfReports";
import FilterNav from "components/FilterNav/FilterNav";

const Reports = () => {
  const [isDescription, setIsDescription] = useState(true);

  const handleToggle = useCallback(() => {
    setIsDescription(!isDescription);
  }, [isDescription]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1 flex-wrap gap-2">
        <h4 className="mb-0 ">List of Reports</h4>

        <div className="d-flex gap-3 align-items-center ms-auto">
          <h5 className="mb-0">Show Description</h5>
          <MDBSwitch
            onChange={handleToggle}
            className="custom-toggle my-auto"
            defaultChecked
          />
        </div>
      </div>
      <div>
        <FilterNav />
      </div>
      <ListOfReports isDescription={isDescription} />
    </>
  );
};

export default Reports;
