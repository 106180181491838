import React from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

import "./GenericTableView.scss";
import FilterNav from "components/FilterNav/FilterNav";
import DonutChart from "components/Charts/DonutChart";
import BarRecharts from "components/Charts/BarRecharts";
import PageTitle from "components/PageTitle/PageTitle";
import CircleLabel from "components/UI/Circle/CircleLabel";
import { ChartsColor, DefaultBarData } from "helpers/Constant";
import TableRow from "pages/GenericTableView/TableRow";
import genericTableData from "./genericTableData";

const GenericTableView = () => {
  const location = useLocation();
  const elementData = location.state?.donutData;
  const data = location.state?.data;
  const color = location.state?.color;
  const headerStyle = { backgroundColor: color };

  return (
    <>
      <PageTitle title={"Generic Table View"} />

      <FilterNav />
      <Row className="">
        <Col md={3}>
          <Col className="card-layout">
            <DonutChart data={data} colors={ChartsColor} />
            <CircleLabel data={data} colors={ChartsColor} />
          </Col>
          <Col className="card-layout card-with-background">
            <BarRecharts
              detailedBarData={DefaultBarData}
              dataKeys={["value", "value1", "value2"]}
              loading={false}
            />
            <CircleLabel data={data} colors={ChartsColor} />
          </Col>
        </Col>

        <Col md={9}>
          <div className="card-layout table-view">
            <h5>Report</h5>
            <div className="table-content p-4">
              <Row className="table-header" style={headerStyle}>
                {elementData?.name} report
              </Row>
              <Row>
                <Col className="left-col-header" style={headerStyle}></Col>
                {["Candidates", "Position", "Decision"].map((item) => (
                  <Col className="col-head" key={item}>
                    {item}
                  </Col>
                ))}
              </Row>
              {genericTableData.map((item) => (
                <TableRow
                  key={item.rowTitle}
                  leftColHeader={item.rowTitle}
                  color={color}
                  contetList={item.contetList}
                />
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default GenericTableView;
