import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div>
        © 2024 {`<`}ESAI.Work{`>`} All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
