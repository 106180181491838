import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Authenticator } from "@aws-amplify/ui-react";

import "./index.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-datepicker/dist/react-datepicker.css";

import "./i18n.js";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "context/UserContext";
import { FilterProvider } from "context/FilterContext";
import { AppProvider } from "context/AppContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <BrowserRouter>
        <UserProvider>
          <FilterProvider>
            <AppProvider>
              <QueryClientProvider client={queryClient}>
                <App />
              </QueryClientProvider>
            </AppProvider>
          </FilterProvider>
        </UserProvider>
      </BrowserRouter>
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
