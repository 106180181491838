import React, { memo, useContext, useMemo, useState } from "react";
import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import CustomFilter from "components/Reports/CustomFilter";
import { UserContext } from "context/UserContext";
import { FilterContext } from "context/FilterContext";
import {
  convertGridColumnMenu,
  convertStageCodeToName,
  formatDate,
  reformatString,
} from "helpers/ReusableFunctions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PitchResponseChoices } from "helpers/Constant";

const renderSocialLinksActions = (params) => {
  return (
    <Link to={params.value} target="_blank" rel="noreferrer">
      {params.value}
    </Link>
  );
};

const PitchedCandidatesTab = ({
  apiRef = null,
  loading = false,
  pitchedCandidates = [],
  onRowClick = () => {},
}) => {
  const { t } = useTranslation();
  const { language } = useContext(UserContext);
  const { filterStore } = useContext(FilterContext);

  const sortableColumn = true;
  const pinnableColumn = true;

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const customStageColumnSorting = (v1, v2, ...rest) => {
    let row1_id = rest[0].id;
    let row2_id = rest[1].id;

    let row1 = rest[0].api.getRow(row1_id);
    let row2 = rest[1].api.getRow(row2_id);

    let intV1 = v1 !== "New Candidate" ? +row1?.[rest[0].field] : 0;
    let intV2 = v2 !== "New Candidate" ? +row2?.[rest[0].field] : 0;
    return intV1 - intV2;
  };

  const japaneseName = useMemo(
    () => [
      {
        field: "kanji_last",
        headerName: t("performanceReportText.lastNameJ"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "kanji_first",
        headerName: t("performanceReportText.firstNameJ"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const englishName = useMemo(
    () => [
      {
        field: "first_name",
        headerName: t("performanceReportText.firstName"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "last_name",
        headerName: t("performanceReportText.lastName"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const columns = useMemo(
    () => [
      ...(!["no", "maybe"].includes(filterStore.PitchResponse)
        ? language === "ja"
          ? japaneseName
          : englishName
        : []),
      {
        field: "current_employer",
        headerName: t("performanceReportText.employer"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "current_title",
        headerName: t("performanceReportText.title"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        type: "string",
        field: "current_stage",
        headerName: "Latest Stage",
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        valueGetter: ({ row }) =>
          convertStageCodeToName(
            row?.app_position?.current_stage,
            row?.app_position?.pitch_response.response
          ),
        sortComparator: (v1, v2) => {
          const intV1 = customStageColumnSorting;
          const intV2 = customStageColumnSorting;
          return intV1 - intV2;
        },
      },
      {
        field: "last_activity_date",
        headerName: "Date of Last Activity",
        width: 200,
        sortable: sortableColumn,
        pinnableColumn: pinnableColumn,
        filterOperators: filterOperators,
        renderCell: ({ value }) => {
          return formatDate(value);
        },
      },
      ...(!["maybe"].includes(filterStore.PitchResponse)
        ? [
            {
              field: "initial_response",
              headerName: t("performanceReportText.initialResponse"),
              width: 200,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              valueGetter: ({ row }) =>
                reformatString(
                  PitchResponseChoices[
                    row?.app_position?.pitch_response?.response
                  ]
                ),
            },
          ]
        : []),
      ...(["no"].includes(filterStore.PitchResponse)
        ? [
            {
              field: "response_reason",
              headerName: t("barChartText.reason"),
              width: 200,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
            },
          ]
        : []),
      {
        field: "salary",
        headerName: t("performanceReportText.salary"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "salary_breakdown",
        headerName: t("performanceReportText.salaryBreakdown"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "english",
        headerName: t("performanceReportText.english"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "japanese",
        headerName: t("performanceReportText.japanese"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      ...(!["no", "maybe"].includes(filterStore.PitchResponse)
        ? [
            ...(language === "en" ? japaneseName : englishName),
            {
              field: "linkedin_url",
              headerName: t("performanceReportText.linkedinUrl"),
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "twitter_url",
              headerName: t("performanceReportText.twitterUrl"),
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "facebook_url",
              headerName: t("performanceReportText.facebookUrl"),
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "github_url",
              headerName: t("performanceReportText.githubUrl"),
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
          ]
        : []),
      {
        field: "age",
        headerName: t("performanceReportText.age"),
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "education_history",
        headerName: t("performanceReportText.educationHistory"),
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "work_history",
        headerName: t("performanceReportText.workHistory"),
        width: 500,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [englishName, filterStore.PitchResponse, japaneseName]
  );

  const [columnsOrder, setColumnsOrder] = useState(columns);

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  return (
    <div className="container job-details-card">
      <div className="cstm-mui-datagrid">
        <DataGridPro
          loading={loading}
          rows={pitchedCandidates}
          columns={columnsWithOperators}
          slotProps={{
            filterPanel: {
              sx: { maxWidth: "calc(90vw - 24px)" },
            },
          }}
          getRowId={(row) => row?.id}
          onColumnOrderChange={(c) => {
            setColumnsOrder((prevCols) => {
              const newCols = [...prevCols];
              newCols.splice(c.oldIndex, 1);
              newCols.splice(c.targetIndex, 0, c.column);
              return newCols;
            });
          }}
          initialState={{
            ...columns.initialState,
            columns: columns.initialState?.columns,
          }}
          onRowClick={(params) => onRowClick(params?.row)}
          hideFooter
          localeText={convertGridColumnMenu(language)}
          apiRef={apiRef}
          rowsLoadingMode={"server"}
        />
      </div>
    </div>
  );
};

export default memo(PitchedCandidatesTab);
