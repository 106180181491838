import React from "react";
import {
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import "./Submenu.scss";

const Submenu = ({
  tabs = [],
  FilterNav = <></>,
  basicActive = "",
  setBasicActive = () => {},
}) => {
  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  return (
    <div>
      <MDBTabs className="mb-4">
        {tabs.map((tab, i) => (
          <MDBTabsItem key={i}>
            <MDBTabsLink
              onClick={() => handleBasicClick(tab.title)}
              active={basicActive === tab.title}
              className={basicActive === tab.title && "triangle-up"}>
              <span className="text-capitalize">{tab.title}</span>
              {/* <span>{tab.title.charAt(0)}</span>
              <span className='text-lowercase'>{tab.title.slice(1)}</span> */}
            </MDBTabsLink>
          </MDBTabsItem>
        ))}
      </MDBTabs>
      {FilterNav}
      <MDBTabsContent>
        {tabs.map((tab, i) => (
          <MDBTabsPane key={i} show={basicActive === tab.title}>
            {tab.content}
          </MDBTabsPane>
        ))}
      </MDBTabsContent>
    </div>
  );
};

export default Submenu;
