import React, { useContext, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

import SelectUI from "components/Select/SelectUI";
import { FilterContext } from "context/FilterContext";
import { FILTER_TYPES, filterTypesOptions } from "helpers/Constant";
import { UserContext } from "context/UserContext";
import { createCustomReport, updateCustomReport } from "services/FetchApiData";

const SaveAsModal = ({
  reportName = "",
  handleCancel = () => {},
  apiRef,
  extraTopFiltersToSave = {},
}) => {
  const { leftHandFilterParams, isPresentationEnabled } =
    useContext(FilterContext);
  const { userGroup, setCustomReports } = useContext(UserContext);

  const location = useLocation();
  const state = location.state;

  const exportedState = apiRef?.current?.exportState();

  const initialFormData = {
    reportName: reportName,
    filterTypes: [...filterTypesOptions],
  };

  const [formData, setFormData] = useState(initialFormData);
  const [selectedFilterOption, setSelectedFilterOption] = useState([
    {
      value: "*",
      label: "Select ALL",
    },
    ...filterTypesOptions,
  ]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const selectedFilterValue = (e) => {
    setSelectedFilterOption(e);
    setFormData((prevFormData) => ({
      ...prevFormData,
      filterTypes: [...e],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Perform form validation
    if (!formData.reportName.trim()) {
      newErrors.reportName = "Required";
    }
    if (formData.filterTypes.length === 0) {
      newErrors.filterTypes = "Required";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const current_custom_report = {
      title: formData.reportName,
      navigate: window.location.pathname,
      accessUserType:
        userGroup.includes("internal") && !isPresentationEnabled
          ? "internal"
          : "external",
    };

    const selectedFilter = formData.filterTypes.map((f) => f.value);
    const isLeftHandFilter = selectedFilter.includes(
      FILTER_TYPES.LEFT_HAND_FILTERS
    );
    const isTableFilter = selectedFilter.includes(FILTER_TYPES.TABLE_FILTERS);
    const isTableSorting = selectedFilter.includes(FILTER_TYPES.TABLE_SORTING);

    current_custom_report[FILTER_TYPES.LEFT_HAND_FILTERS] = {
      ...(isLeftHandFilter ? leftHandFilterParams : {}),
      ...(!isEmpty(extraTopFiltersToSave)
        ? {
            ...extraTopFiltersToSave,
          }
        : {}),
    };

    if (!isTableFilter) {
      delete exportedState.filter;
    }
    if (!isTableSorting) {
      delete exportedState.sorting;
    }

    current_custom_report["currentGridState"] = exportedState;

    const navigateToReportsListPage = () => {
      navigate("/reports", { relative: true });
    };

    if (!isEmpty(state?.customReportDetail)) {
      const { id, version } = state.customReportDetail;
      try {
        setIsLoading(true);
        const res = await updateCustomReport(
          id,
          version,
          current_custom_report
        );
        const resData = res.data.data.save_report;
        setCustomReports((prevReports) =>
          prevReports.map((report) =>
            report.id === resData.id ? resData : report
          )
        );
        navigateToReportsListPage();
      } catch (error) {
        console.log(error);
      } finally {
        // Reset the form
        setIsLoading(false);
      }
    } else {
      try {
        setIsLoading(true);
        const res = await createCustomReport(current_custom_report);
        setCustomReports((prevReports) => [
          ...prevReports,
          res.data?.data?.save_report,
        ]);
        navigateToReportsListPage();
      } catch (error) {
        console.log(error);
      } finally {
        // Reset the form
        setIsLoading(false);
      }
    }
    setFormData(initialFormData);
    handleCancel();
    setErrors({});
  };

  return (
    <div className="saveAs-card">
      <form onSubmit={handleSubmit}>
        <div>
          <div className="user-label">
            Report Name:
            {errors.reportName && (
              <span className="error">{errors.reportName}</span>
            )}
          </div>
          <input
            className="w-100"
            type="text"
            name="reportName"
            value={formData.reportName}
            onChange={handleInputChange}
            autoFocus={true}
          />
        </div>

        <div className="mb-4">
          <div className="user-label">
            Include Filters:
            {errors.filterTypes && (
              <span className="error">{errors.filterTypes}</span>
            )}
          </div>
          <div>
            <SelectUI
              options={filterTypesOptions}
              isMulti={true}
              isClearable={true}
              selectedValue={selectedFilterValue}
              isRenderInputCheckbox={true}
              allowSelectAll={true}
              value={selectedFilterOption}
            />
          </div>
        </div>

        <Row className="justify-content-between">
          <Col
            className="col-content col-sm-0 "
            style={{
              width: "8rem",
            }}>
            <button
              type="submit"
              className="action-button w-100 d-flex justify-content-center"
              disabled={isLoading}>
              {isLoading ? (
                <div>
                  <Spinner
                    animation="border"
                    size="sm"
                    className="text-white"
                    style={{ width: "1rem" }}
                  />
                </div>
              ) : (
                <div className="m-0">Save</div>
              )}
            </button>
          </Col>
          <Col className="col-content col-sm-0 ">
            <button
              type="button"
              className="action-button-outline justify-content-center px-4 mb-0 me-0 w-100"
              onClick={handleCancel}>
              <div className=" m-0">Cancel</div>
            </button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default SaveAsModal;
