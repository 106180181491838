import { Spinner } from "react-bootstrap";
import "./loader.scss";

const MetricsLoader = () => {
  return (
    <Spinner
      className="metrics-loader p-3 border-3"
      animation="border"
      variant="primary"
    />
  );
};

export default MetricsLoader;
