import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import "./dateRangeFilter.scss";
import { FilterContext } from "context/FilterContext";
import Datepicker from "components/DatePicker/DatePicker";
import CustomToast from "components/Toast";
import CompareSelect from "components/FilterNav/DateRangeFilter/CompareSelect";
import { ComparisonStartDates } from "helpers/Constant";
import ErrorIcon from "assets/svg/error-fill.svg";

const DateRangeFilter = ({
  compareRadioSelected,
  onCompareRadioSelectChange,
  isDisabled = false,
}) => {
  const { filterStore, setFilterStore } = useContext(FilterContext);
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [minStartDate, setMinStartDate] = useState(ComparisonStartDates.yes);
  const [showToast, setShowToast] = useState(false);

  const setValidRadio = ({ start, end }) => {
    const s = moment(start);
    const e = moment(end);
    const difference = e.diff(s, "days");

    const isLeapYear =
      start.getFullYear() / 4 === 0 || end.getFullYear() / 4 === 0;

    const setPeriodValue = () => {
      if (filterStore.count !== 0) {
        onCompareRadioSelectChange("Period");
      }
    };

    if (
      (!compareRadioSelected || compareRadioSelected === "Month") &&
      difference > 30
    ) {
      setPeriodValue();
    }

    if (compareRadioSelected === "Quarter" && difference > 91) {
      setPeriodValue();
    }

    if (isLeapYear && compareRadioSelected === "Year") {
      if (difference > 365) {
        setPeriodValue();
      }
    } else if (!isLeapYear && compareRadioSelected === "Year") {
      if (difference > 364) {
        setPeriodValue();
      }
    }
  };

  // comaprision end date select handler
  const selectedCompareValue = (e) => {
    const updateRadioFilterStore = (unit) => {
      updateFilterStoreDates(e.value, unit);
    };

    if (e.value === "Custom Date") {
      document.getElementById("CompareStartDate").focus();
    } else {
      // if (
      //   new Date(e.value.start) <
      //   ComparisonStartDates[filterStore.PitchResponse || "yes"]
      // ) {
      //   setShowToast(true);
      // } else {
      //   setShowToast(false);
      if (e.periodType === "Month") {
        updateRadioFilterStore("month");
      } else if (e.periodType === "Quarter") {
        updateRadioFilterStore("quarter");
      } else if (e.periodType === "Year") {
        updateRadioFilterStore("year");
      }
      // }
    }
  };

  const handleRangeStartChange = (date) => {
    if (date) {
      setRangeStart(date);
      updateFilterStoreDates({ start: date, end: filterStore.endDate });
    }
  };

  const handleRangeEndChange = (date) => {
    if (date) {
      let startDate = new Date(filterStore.startDate);
      setRangeEnd(date);
      if (moment(filterStore.startDate).diff(moment(date), "days") > 1) {
        date.setHours(0, 0, 0);
        startDate.setHours(23, 59, 59);
        updateFilterStoreDates({ start: date, end: startDate });
        return;
      }
      if (filterStore.startDate.getFullYear() > date.getFullYear()) {
        date.setFullYear(filterStore.startDate.getFullYear());
      }
      startDate.setHours(0, 0, 0);
      date.setHours(23, 59, 59);
      updateFilterStoreDates({ start: startDate, end: date });
    }
  };

  const getUnitVal = (startDate, endDate) => {
    const start = moment(startDate);
    const end = moment(endDate);
    const difference = end.diff(start, "days");

    const monthStart = start.date() === 1;
    const monthEnd = end.endOf("month").diff(end, "days") === 0;
    const withinMonth = difference < 32;

    const mainQuarterStart = monthStart && [0, 3, 6, 9].includes(start.month());
    const mainQuarterEnd = monthEnd && [2, 5, 8, 11].includes(end.month());
    const withinQuarter = difference < 93 && difference > 30;

    const yearStart = moment(startDate).startOf("year");
    const yearEnd = moment(endDate).endOf("year");
    const isYearStart = yearStart.diff(start, "days") === 0;
    const isYearEnd = yearEnd.diff(end, "days") === 0;
    const withinYear = difference < 367 && difference > 92;

    if (
      ((monthStart && monthEnd && withinMonth) || withinMonth) &&
      start.month() === end.month()
    ) {
      return "month";
    } else if (
      (mainQuarterStart && mainQuarterEnd && withinQuarter) ||
      (monthStart && monthEnd && withinQuarter) ||
      withinQuarter
    ) {
      return "quarter";
    } else if (
      (isYearStart && isYearEnd && withinYear) ||
      (withinYear && start.year() === end.year()) ||
      withinYear
    ) {
      return "year";
    } else {
      return "custom";
    }
  };

  const updateFilterStoreDates = ({ start, end }, unitVal) => {
    setValidRadio({ start, end });
    const calculatedUnitVal = getUnitVal(start, end);
    onCompareRadioSelectChange(calculatedUnitVal);

    setFilterStore((prev) => {
      return {
        ...prev,
        startDate: start,
        endDate: end,
        unit: unitVal ? unitVal : calculatedUnitVal,
      };
    });
  };

  const handleInvalidDate = (value) => {
    if (value && value.trim().length >= 8) {
      if (
        new Date(value) <
        ComparisonStartDates[filterStore.PitchResponse || "yes"]
      ) {
        setShowToast(true);
      } else {
        setShowToast(false);
      }
    }
  };

  useEffect(() => {
    setMinStartDate(ComparisonStartDates[filterStore.PitchResponse || "yes"]);
  }, [filterStore.PitchResponse]);

  return (
    <div className="date-range-filter d-flex">
      <div className="first-date-picker">
        <Datepicker
          selected={filterStore?.startDate || rangeStart}
          onChange={handleRangeStartChange}
          id={"CompareStartDate"}
          minDate={minStartDate}
          onChangeRaw={(e) => handleInvalidDate(e.target.value)}
          isDisabled={isDisabled}
        />
      </div>
      <div className="second-date-picker">
        <Datepicker
          minDate={minStartDate}
          selected={filterStore?.endDate || rangeEnd}
          onChange={handleRangeEndChange}
          isDisabled={isDisabled}
        />
      </div>
      <CompareSelect
        selectedCompareValue={selectedCompareValue}
        isDisabled={isDisabled}
      />
      {showToast && (
        <CustomToast
          show={showToast}
          setShow={setShowToast}
          message="This action is not possible as there is no prior data."
          header="Error"
          className="custom-error-toast"
          icon={<img src={ErrorIcon} alt="error" />}
        />
      )}
    </div>
  );
};

export default DateRangeFilter;
