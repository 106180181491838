import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  LabelList,
} from "recharts";
import { Spinner } from "react-bootstrap";

import "./Charts.scss";

import { UserContext } from "context/UserContext";
import { pieChartNameConvention } from "helpers/Constant";
import { capitalizeLetter, nameConvention } from "helpers/ReusableFunctions";
import { FilterContext } from "context/FilterContext";

const DonutChart = ({ data, colors, loading }) => {
  const { setSelectedGraph } = useContext(UserContext);
  const { setFilterStore } = useContext(FilterContext);

  const navigate = useNavigate();

  const handleClick = (elementData, color) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      PitchResponse: nameConvention(pieChartNameConvention, elementData)?.value,
    }));
    if (nameConvention(pieChartNameConvention, elementData)?.value === "no") {
      setSelectedGraph("Bar Chart");
    } else {
      setSelectedGraph("Pie Chart");
    }
    navigate("/performancereport", {
      state: {
        donutData: elementData,
        data: data,
        color: color,
      },
    });
  };

  // To display percentage of pie chart
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radian = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.7;
    const x = cx + radius * Math.cos(-midAngle * radian);
    const y = cy + radius * Math.sin(-midAngle * radian);

    return (
      <text
        x={x}
        y={y}
        fill={colors[index % colors.length]} // Apply color based on index
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: "16px" }}>
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const isAllValuesZero = (dataArray) => {
    return dataArray?.every((item) => item.value === 0);
  };

  const modifiedData = isAllValuesZero(data)
    ? [...data, { name: "No Data", value: 1 }] // Add a slice with a value of 0
    : data;

  return (
    <ResponsiveContainer height={275} className={loading ? "d-grid" : ""}>
      {!loading ? (
        <PieChart>
          <Pie
            data={modifiedData}
            cx="50%"
            cy="50%"
            innerRadius={40}
            outerRadius={90}
            fill="#8884d8"
            paddingAngle={0}
            dataKey="value"
            label={!isAllValuesZero(data) && renderCustomizedLabel}
            isAnimationActive={false}>
            {modifiedData?.map((entry, index) => {
              if (entry !== "No Data")
                return (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                    onClick={() =>
                      handleClick(entry, colors[index % colors.length])
                    }
                    style={{ cursor: "pointer" }}
                  />
                );
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              );
            })}
            {isAllValuesZero(data) && (
              <Label value={"No Data"} position="center" fill="#4433ae" />
            )}
            {!isAllValuesZero(data) && (
              <LabelList
                dataKey={(entry) =>
                  capitalizeLetter(
                    nameConvention(pieChartNameConvention, entry)?.value
                  )
                }
                position="inside"
                style={{ fill: "#fff" }}
              />
            )}
          </Pie>
        </PieChart>
      ) : (
        <div className="d-flex justify-content-center align-items-center p-4">
          <Spinner animation="border" />
        </div>
      )}
    </ResponsiveContainer>
  );
};

export default DonutChart;
