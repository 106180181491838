import React, { memo, useContext, useEffect, useMemo } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";

import { getSocialLinksImagesWithLinkArrayFromCandidateObj } from "helpers/ReusableFunctions";
import { FilterContext } from "context/FilterContext";

const ProfileModalHeader = ({
  title = "",
  currentData = {},
  setCurrentData = () => {},
  currentIndex = 0,
  setCurrentIndex = () => {},
  reportsData = [],
  closeModal = () => {},
  isCandidatesAccess,
}) => {
  const { filterStore } = useContext(FilterContext);
  const location = useLocation();
  const isCandidatePath = location?.pathname?.includes("/candidates/");
  const isHideNavigatorButtons = useMemo(
    () => isCandidatesAccess && isCandidatePath,
    [isCandidatesAccess, isCandidatePath]
  );
  const lastIndex = reportsData?.length - 1;
  const handleBack = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => (prev === lastIndex ? prev : prev + 1));
  };

  useEffect(() => {
    const data = reportsData[currentIndex];
    setCurrentData(data);
    // eslint-disable-next-line
  }, [currentIndex]);

  return (
    <div className="w-100 d-flex justify-content-between flex-wrap gap-2 gap-lg-0 position-relative p-3">
      <h4>{title}</h4>
      <div className="d-flex flex-wrap justify-content-center gap-3 order-last order-lg-0 flex-grow-1 flex-none social-mobile-screen ">
        {!["maybe"].includes(filterStore.PitchResponse) &&
          getSocialLinksImagesWithLinkArrayFromCandidateObj(currentData)
            .filter((pf) => !!pf.url)
            .map((profile, i) => (
              <div key={i}>
                <Link
                  to={profile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={profile?.svg}
                    alt="Logo"
                    className="logo-image-icon"
                  />
                </Link>
              </div>
            ))}
      </div>

      {!!reportsData.length && !isHideNavigatorButtons && (
        <div className="scroll d-flex align-items-center flex-shrink-0 justify-content-center float-start float-sm-none flex">
          <h4>
            <IoIosArrowBack
              onClick={() => handleBack()}
              style={{
                cursor: "pointer",
                color:
                  currentData.id === reportsData[0].id ? "lightgray" : "black",
              }}
            />
            <IoIosArrowForward
              onClick={() => handleNext()}
              style={{
                cursor: "pointer",
                color:
                  currentData.id === reportsData[lastIndex].id
                    ? "lightgray"
                    : "black",
              }}
            />
          </h4>
          <span>
            {currentIndex + 1} / {reportsData.length}
          </span>
        </div>
      )}
      <button
        className={`
          ${isHideNavigatorButtons ? "" : "d-sm-none"}
        d-block btn btn-sm btn-dark p-2 m-0 bg-transparent link-hover shadow-none rounded-circle`}
        style={{
          color: "#6754e2",
          top: "-20px",
          right: "-20px",
        }}
        onClick={closeModal}
      >
        <AiOutlineClose style={{ fontSize: "25px", fontWeight: "bolder" }} />
      </button>
    </div>
  );
};

export default memo(ProfileModalHeader);
