import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "./tooltip.scss";

const CustomToolTip = ({
  placement = "top",
  text = "",
  children = <></>,
  ...rest
}) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip id={`tooltip-${placement}`} {...rest}>
          {text}
        </Tooltip>
      }>
      {children}
    </OverlayTrigger>
  );
};

export default CustomToolTip;
