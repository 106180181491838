import React from "react";
import { Modal } from "react-bootstrap";
import "./ModalBox.scss";

const ModalBox = ({
  show,
  onHide,
  title,
  content,
  size = "xl",
  hideCloseIcon = false,
  customStyle = {},
  customClass = "",
}) => {
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      size={size || "xl"}
      style={customStyle}
      className={customClass}>
      {title && (
        <Modal.Header closeButton={!hideCloseIcon}>
          <h4>{title}</h4>
        </Modal.Header>
      )}
      <Modal.Body className="">{content}</Modal.Body>
    </Modal>
  );
};

export default ModalBox;
