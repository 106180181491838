const genericTableData = [
  {
    rowTitle: "abc",
    contetList: [
      "Software Engineer",
      "Software Engineer",
      "Offer has been accepted",
    ],
  },
  {
    rowTitle: "xyz",
    contetList: [
      "Full stack developer",
      "Full stack developer",
      "Not Accepted Yet",
    ],
  },
  {
    rowTitle: "pqr",
    contetList: [
      "Full stack developer",
      "Full stack developer",
      "Not Accepted Yet",
    ],
  },
];

export default genericTableData;
