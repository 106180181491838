import React, { useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FilterContext } from "context/FilterContext";

const CustomDays = ({ handleCloseModal, periodType = "Month" }) => {
  const { filterStore, setFilterStore } = useContext(FilterContext);
  const [inputValue, setInputValue] = useState(filterStore.count);
  const [customError, setCustomError] = useState(null);

  const handleInputChange = (event) => {
    setInputValue(parseInt(event.target.value));
  };

  const handleSubmit = () => {
    if (!inputValue) {
      setCustomError("Invalid number");
      return;
    } else {
      if (inputValue <= 0) {
        setCustomError("Invalid number");
        return;
      } else if (inputValue > 24) {
        setCustomError("You can only compare up to 24 periods");
        return;
      } else {
        setCustomError(null);
      }

      setFilterStore((prev) => ({ ...prev, count: inputValue }));
      handleCloseModal();
    }
  };

  return (
    <div className="custom-content card">
      <Row>
        <Col md={12} className="p-4 d-flex align-items-center gap-2">
          <div className="user-label">Comparison {periodType}(s) </div>
          {customError && (
            <span className="error" style={{ marginTop: "10px" }}>
              {customError}
            </span>
          )}
        </Col>
        <Col md={9} className="p-4 pt-0">
          <input
            type="number"
            name="days"
            value={inputValue}
            max={24}
            onChange={handleInputChange}
          />
          <p
            className="text-muted text-sm-start mb-0"
            style={{
              fontSize: "14px",
            }}>
            Max 24 allowed
          </p>
        </Col>
        <Col md={3} className="align-self-center pt-0 p-4">
          {periodType}(s)
        </Col>

        <Col md={12} className="user-card-button">
          <div className="action-button mb-4">
            <div className="action-text" onClick={handleSubmit}>
              Select
            </div>
          </div>
          <div className="action-button-outline" onClick={handleCloseModal}>
            <div className="action-text">Cancel</div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CustomDays;
