import axios from "axios";
import { Auth } from "aws-amplify";
import { API_URL } from "./config";

const AxiosClient = axios.create({ baseURL: API_URL });

AxiosClient.interceptors.request.use(
  async (config) => {
    const {
      idToken: { jwtToken },
    } = await Auth.currentSession();

    config.headers = {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

AxiosClient.interceptors.response.use(
  (response) => {
    if (response.data.errors) {
      console.log("GraphQL Errors:", response.data.errors);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default AxiosClient;
