import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./datePicker.scss";

const Datepicker = ({
  placeholderText = "mm/dd/yyyy",
  selected,
  minDate,
  maxDate,
  onChange,
  id,
  onChangeRaw,
  isDisabled = false,
}) => {
  const [date, setDate] = useState(selected);

  const selectDateDate = (d) => {
    setDate(d);
    onChange(d);
  };

  return (
    <DatePicker
      placeholderText={placeholderText}
      dateFormat="dd MMM yyyy"
      selected={selected || date}
      minDate={minDate}
      maxDate={maxDate}
      onChange={selectDateDate}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      id={id ? id : null}
      onChangeRaw={onChangeRaw}
      disabled={isDisabled}
    />
  );
};

export default Datepicker;
