import React from "react";
import DateLabel from "./DateLabel";
import SelectUI from "components/Select/SelectUI";
import {
  quarter,
  thisMonthEnd,
  thisMonthStart,
  thisQuarterEnd,
  thisQuarterStart,
  today,
} from "helpers/Constant";

const CompareSelect = ({ selectedCompareValue, isDisabled = false }) => {
  // comaprision start date select options
  const compareFromOptions = (() => {
    // this month, quarter or year
    const thisYearStart = new Date(today.getFullYear(), 0, 1);
    const thisYearEnd = new Date(today.getFullYear(), 12, 0, 23, 59, 59);

    // last month, quarter or year
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
    const prevQuarterStart = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    const prevQuarterEnd = new Date(
      prevQuarterStart.getFullYear(),
      prevQuarterStart.getMonth() + 3,
      0, 23, 59, 59
    );
    const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
    const lastYearEnd = new Date(today.getFullYear() - 1, 12, 0, 23, 59, 59);

    // month, quarter or year to date
    const todayEnd = new Date( 
      today.getFullYear(),
      today.getMonth(), 
      today.getDate(), 
      23, 59, 59);
    // thisQuarterStart
    // thisYearStart

    return [
      {
        label: (
          <DateLabel
            text="This month"
            date1={thisMonthStart}
            date2={thisMonthEnd}
            displayOnlyMonth={true}
          />
        ),
        value: { start: thisMonthStart, end: thisMonthEnd },
        periodType: "Month",
      },
      {
        label: (
          <DateLabel
            text="This quarter"
            date1={thisQuarterStart}
            date2={thisQuarterEnd}
            displayBothDate={true}
          />
        ),
        value: { start: thisQuarterStart, end: thisQuarterEnd },
        periodType: "Quarter",
      },
      {
        label: (
          <DateLabel
            text="This year"
            date1={thisYearStart}
            date2={thisYearEnd}
            displayBothDate={true}
          />
        ),
        value: { start: thisYearStart, end: thisYearEnd },
        periodType: "Year",
      },
      {
        label: (
          <DateLabel
            text="Last month"
            date1={lastMonthStart}
            date2={lastMonthEnd}
            displayOnlyMonth={true}
          />
        ),
        value: { start: lastMonthStart, end: lastMonthEnd },
        periodType: "Month",
      },
      {
        label: (
          <DateLabel
            text="Last quarter"
            date1={prevQuarterStart}
            date2={prevQuarterEnd}
            displayBothDate={true}
          />
        ),
        value: { start: prevQuarterStart, end: prevQuarterEnd },
        periodType: "Quarter",
      },
      {
        label: (
          <DateLabel
            text="Last year"
            date1={lastYearStart}
            date2={lastYearEnd}
            displayBothDate={true}
          />
        ),
        value: { start: lastYearStart, end: lastYearEnd },
        periodType: "Year",
      },
      {
        label: (
          <DateLabel
            text="Month to date"
            date1={thisMonthStart}
            date2={today}
            displayBothDate={true}
          />
        ),
        value: { start: thisMonthStart, end: todayEnd },
        periodType: "Month",
      },
      {
        label: (
          <DateLabel
            text="Quarter to date"
            date1={thisQuarterStart}
            date2={today}
            displayBothDate={true}
          />
        ),
        value: { start: thisQuarterStart, end: todayEnd },
        periodType: "Quarter",
      },
      {
        label: (
          <DateLabel
            text="Year to date"
            date1={thisYearStart}
            date2={today}
            displayBothDate={true}
          />
        ),
        value: { start: thisYearStart, end: todayEnd },
        periodType: "Year",
      },
      { label: "Custom date", value: "Custom Date" },
    ];
  })();

  return (
    <div className="compare-from-select">
      <SelectUI
        options={compareFromOptions}
        placeholder={"Timeline"}
        selectedValue={selectedCompareValue}
        defaultValue={true}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default CompareSelect;
