import React from "react";
import { Col, Row } from "react-bootstrap";

const HistoryDetailList = ({ title, value }) => {
  return (
    <Col>
      <Row className="align-items-baseline">
        <Col md="auto">
          <p className="profile_subsection-dim_title mb-0">{title}</p>
        </Col>
        <Col md="auto">
          {value ? (
            <p className="profile_subsection-subtitle mb-0 ">{value}</p>
          ) : (
            <p className="text-center mb-0">Unknown</p>
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default HistoryDetailList;
