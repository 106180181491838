import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./assets/locales/en/translation.json";
import translationJA from "./assets/locales/ja/translation.json";

const defaultLng = localStorage.getItem("language") || "en";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    ja: {
      translation: translationJA,
    },
  },
  lng: defaultLng,
  fallbackLng: defaultLng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
