import { Col, Row } from "react-bootstrap";

const TableCell = ({ content }) => {
  return <Col className="col-content last-col">{content}</Col>;
};

const TableRow = ({ leftColHeader, contetList, color }) => {
  const headerStyle = { backgroundColor: color };

  return (
    <Row>
      <Col className="left-col-header" style={headerStyle}>
        {leftColHeader}
      </Col>
      {contetList.map((item) => (
        <TableCell key={item} content={item} />
      ))}
    </Row>
  );
};

export default TableRow;
