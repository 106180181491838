import React, { useContext, useState } from "react";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

import { UserContext } from "context/UserContext";
import ModalBox from "../Modal/ModalBox";
import EditPermissionCard from "./EditPermissionCard";
import AddPermissionCard from "./AddPermissionCard";
import { convertGridColumnMenu } from "helpers/ReusableFunctions";

import ReportPermissionData from "../../mockdata/ReportPermission.json";
import ActionSvg from "../../assets/svg/action-table.svg";
import AddUserSvg from "../../assets/svg/add-user.svg";
import DeleteUserSvg from "../../assets/svg/delete.svg";

const ReportPermissions = ({ apiRef }) => {
  const { language } = useContext(UserContext);
  const [reportData, setReportData] = useState(ReportPermissionData);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowEditModal = () => {
    setShowEditModal(true);
  };
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const ReportsData = reportData?.map((user, index) => ({
    applicantreport: user.reports.includes("Applicant Report"),
    clientapplicantreport: user.reports.includes("Client Applicant Report"),
    pipelinereport: user.reports.includes("Pipeline Report"),
    ...user,
  }));

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 30,
      sortable: false,
    },
    {
      field: "user",
      headerName: "User",
      width: 250,
      sortable: false,
    },
    {
      field: "applicantreport",
      headerName: "Applicant Report",
      width: 250,
      sortable: false,
      renderCell: renderReportCell,
    },
    {
      field: "clientapplicantreport",
      headerName: "Client Applicant Report",
      width: 250,
      sortable: false,
      renderCell: renderReportCell,
    },
    {
      field: "pipelinereport",
      headerName: "Pipeline Report",
      width: 250,
      sortable: false,
      renderCell: renderReportCell,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: renderActionsCell,
    },
  ];

  const intialHiddenColumns = {
    id: false,
  };
  const getRowHeight = (params) => {
    return 90; // In pixels
  };

  function renderActionsCell(params) {
    // Implement your custom action component or logic here
    return (
      <div className="d-flex gap-2">
        <div className="action-svg" onClick={handleShowEditModal}>
          <img src={ActionSvg} alt="action" />
        </div>
        <div className="action-delete-svg">
          <img src={DeleteUserSvg} alt="action" />
        </div>
      </div>
    );
  }

  function renderReportCell(params) {
    return (
      <div className={params.value === false ? "active-cell" : "inactive-cell"}>
        {params.value === true ? "Access" : "No Access"}
      </div>
    );
  }

  return (
    <>
      <div className="card p-3 mt-4">
        <div className="d-flex justify-content-end">
          <div className="action-button" onClick={handleShowModal}>
            <img src={AddUserSvg} alt="Edit Permission" />
            <div className="action-text">Add Permission</div>
          </div>
        </div>
        <div className="cstm-mui-datagrid" style={{ width: "100%" }}>
          <DataGridPro
            rows={ReportsData}
            columns={columns}
            initialState={{
              ...columns.initialState,
              columns: {
                ...columns.initialState?.columns,
                columnVisibilityModel: intialHiddenColumns,
              },
            }}
            disableColumnFilter
            disableColumnHeaderSorting
            disableColumnReorder
            disableColumnMenu
            disableChildrenSorting
            disableColumnResize
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={getRowHeight}
            sx={{
              [`.${gridClasses.columnSeparator} svg`]: {
                visibility: "hidden",
              },
            }}
            onRowClick={(params) => {
              setSelectedRow(params.row);
            }}
            apiRef={apiRef}
            localeText={convertGridColumnMenu(language)}
          />
        </div>
      </div>

      <ModalBox
        show={showModal}
        onHide={handleCloseModal}
        title="Add Report Permission"
        content={
          <AddPermissionCard
            handleCloseModal={handleCloseModal}
            setReportData={setReportData}
            reportData={reportData}
          />
        }
        size="lg"
      />
      <ModalBox
        show={showEditModal}
        onHide={handleCloseEditModal}
        title="Edit Report Permission"
        content={
          <EditPermissionCard
            handleCloseEditModal={handleCloseEditModal}
            setReportData={setReportData}
            selectedRow={selectedRow}
          />
        }
        size="lg"
      />
    </>
  );
};

export default ReportPermissions;
