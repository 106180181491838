import React, { memo, useContext, useState } from "react";

import {
  useGridSelector,
  gridFilteredSortedRowEntriesSelector,
} from "@mui/x-data-grid-pro";

import ProfileModalBody from "./ProfileModalBody";
import { FilterContext } from "context/FilterContext";
import ProfileModalHeader from "./ProfileModalHeader";

const ProfileModalContent = ({
  current = {},
  title = "",
  apiRef = null,
  closeModal = () => {},
  isCandidatesAccess = false,
}) => {
  const { isInternalUser } = useContext(FilterContext);
  const rows = useGridSelector(apiRef, gridFilteredSortedRowEntriesSelector);
  const reportsData = rows?.map((el) => el.model);

  const [currentIndex, setCurrentIndex] = useState(
    reportsData?.findIndex((el) => el.id === current.id)
  );
  const [currentData, setCurrentData] = useState(current);

  return (
    <div>
      <ProfileModalHeader
        title={title}
        currentData={currentData}
        setCurrentData={setCurrentData}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        closeModal={closeModal}
        reportsData={reportsData}
        isCandidatesAccess={isCandidatesAccess}
      />
      <ProfileModalBody
        data={currentData}
        pageNo={currentIndex}
        isInternalUser={isInternalUser}
        isCandidatesAccess={isCandidatesAccess}
      />
    </div>
  );
};

export default memo(ProfileModalContent);
