import React, { useContext, useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { useLocation } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { FilterContext } from "context/FilterContext";
import {
  selectOptionConverter,
  selectOptionConverterSpeciality,
} from "../../helpers/ReusableFunctions";

import "./Sidebar.scss";
import ButtonUI from "../UI/Button/ButtonUI";
import SelectUI from "../Select/SelectUI";
import FilterIcon from "../../assets/svg/filter-icon.svg";
import SearchIcon from "../../assets/svg/search-icon.svg";
import {
  APPLY_FILTER_PAGE,
  differentReportsPath,
  internalReportsPath,
} from "helpers/Constant";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@mui/material";

const Sidebar = () => {
  const { t } = useTranslation();
  const { setMinimize, language, userGroup } = useContext(UserContext);
  const {
    setFilterStore,
    filterStore,
    dropdownData,
    initialFilterStore,
    isPresentationEnabled,
    changeApplyFilterFlag,
    lastAppliedFilterstore,
    setLeftHandFilterParams,
    isDisableApplyButton,
    isInternalUser,
  } = useContext(FilterContext);

  const addStageFilter =
    internalReportsPath.includes(window.location.pathname) ||
    (window.location.pathname === "/activepipelinereport" &&
      (userGroup.includes("external") || isPresentationEnabled));

  const [isDisabled, setIsDisabled] = useState(false);
  const location = useLocation();

  const disableSidebarFilters = location.pathname.includes("/leaderboard");
  const multiDisabledNames = [
    "Positions",
    "Speciality",
    "ClientManagers",
    "Company",
    "Industry",
    "RemotePolicy",
    "Visibility",
    "RequiredLanguages",
    "CompanyName",
    "EnglishLevel",
    "JapaneseLevel",
    "Background",
    "Tags",
    "Title",
  ];
  const allSelectableFieldNames = [
    "Status",
    "CandidateManagers",
    "BusinessManagers",
    "StageManagers",
  ];

  const ClearableSelectionFieldNames = [
    "Positions",
    "Speciality",
    "Company",
    "CandidateManagers",
    "BusinessManagers",
    "ClientManagers",
    "StageManagers",
    "Industry",
    "RemotePolicy",
    "Visibility",
    "Status",
    "RequiredLanguages",
    "CompanyName",
    "EnglishLevel",
    "JapaneseLevel",
    "Background",
    "Tags",
    "Title",
  ];

  if (window.location.pathname === "/successkpireport") {
    const index = allSelectableFieldNames.findIndex(
      (val) => val === "StageManagers"
    );
    allSelectableFieldNames.splice(index, 1);
    multiDisabledNames.push("StageManagers");
  }

  const fieldOptions = [
    ...(location.pathname !== "/candidates"
      ? [
          {
            name: "Positions",
            label: t("sidebarText.position"),
            option: dropdownData?.PositionsOption,
          },
          {
            name: "Speciality",
            label: t("sidebarText.specialization"),
            option: dropdownData?.SpecialityOption,
          },
        ]
      : []),
    ...(addStageFilter
      ? [
          {
            name: "StageManagers",
            label: "Stage",
            option: dropdownData?.StageManagersOptions,
          },
        ]
      : []),

    ...(internalReportsPath.includes(location.pathname) ||
    (differentReportsPath.includes(location.pathname) && isInternalUser)
      ? [
          ...(location.pathname !== "/jobs"
            ? [
                {
                  name: "CandidateManagers",
                  label: "Candidate Manager",
                  option: dropdownData?.CandidateManagerOptions,
                },
                ...(location.pathname !== "/recentlyactivecandidatesreport"
                  ? [
                      {
                        name: "BusinessManagers",
                        label: "Business Manager",
                        option: dropdownData?.BusinessManagerOptions,
                      },
                    ]
                  : []),
              ]
            : []),
          {
            name: "ClientManagers",
            label: "Clients",
            option: dropdownData?.ClientManagerOptions,
          },
        ]
      : []),
    ...(location.pathname === "/jobs"
      ? [
          {
            name: "Industry",
            label: "Industry",
            option: dropdownData?.IndustryOption,
          },
          {
            name: "RemotePolicy",
            label: "Remote Policy",
            option: dropdownData?.RemotePolicy,
          },
          {
            name: "Visibility",
            label: "Visibility",
            option: dropdownData?.Visibility,
          },
          {
            name: "Status",
            label: "Status",
            option: dropdownData?.Status,
          },
          {
            name: "RequiredLanguages",
            label: "Required Languages",
            option: dropdownData?.RequiredLanguages,
          },
          {
            name: "ActiveSearching",
            label: "Active Searching",
            type: "boolean",
          },
        ]
      : []),
    ...(location.pathname === "/candidates"
      ? [
          {
            name: "CompanyName",
            label: "Company Name",
            option: dropdownData?.CompanyName,
          },
          {
            name: "EnglishLevel",
            label: "English Level",
            option: dropdownData?.EnglishLevel,
          },
          {
            name: "JapaneseLevel",
            label: "Japanese Level",
            option: dropdownData?.JapaneseLevel,
          },
          {
            name: "Background",
            label: "Background",
            option: dropdownData?.Background,
          },
          {
            name: "Tags",
            label: "Tags",
            option: dropdownData?.Tags,
          },
          {
            name: "Title",
            label: "Title",
            option: dropdownData?.Title,
          },
        ]
      : []),
  ];

  function replaceNullValWithEmptyArray(objEntries) {
    return objEntries.map(([key, val]) => {
      if (val === null) {
        return [key, []];
      } else {
        return [key, val];
      }
    });
  }

  function updateApplyFilterFlagForCurrentPage() {
    const currentPagePath = location.pathname;
    switch (currentPagePath) {
      case "/":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.dashboard);
      case "/activepipelinereport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.active_pipeline);
      case "/performancereport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.employee_branding);
      case "/candidatepipelinereport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.candidate_pipeline);
      case "/successkpireport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.success_kpi);
      case "/recentlyactivecandidatesreport":
        return changeApplyFilterFlag(
          APPLY_FILTER_PAGE.recently_active_candidate
        );
      case "/timetohirereport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.time_to_hire);
      case "/jobs":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.JOBS);
      default:
        return;
    }
  }

  function isClearFilterActionPossible() {
    const keys = fieldOptions.map((item) => item.name);
    const filteredInitFilter = replaceNullValWithEmptyArray(
      Object.entries(initialFilterStore).filter(([key, val]) =>
        keys.includes(key)
      )
    );
    const filteredLastAppliedFilter = replaceNullValWithEmptyArray(
      Object.entries(lastAppliedFilterstore).filter(([key, val]) =>
        keys.includes(key)
      )
    );
    const finalInitFilterObj = Object.fromEntries(filteredInitFilter);
    const finalLastAppliedFilterObj = Object.fromEntries(
      filteredLastAppliedFilter
    );

    //compare
    return !isEqual(finalInitFilterObj, finalLastAppliedFilterObj);
  }

  function getLeftHandFilters() {
    const keys = fieldOptions.map((item) => item.name);

    const filteredLastAppliedFilter = replaceNullValWithEmptyArray(
      Object.entries(lastAppliedFilterstore).filter(([key, val]) =>
        keys.includes(key)
      )
    );
    const finalLastAppliedFilterObj = Object.fromEntries(
      filteredLastAppliedFilter
    );

    return finalLastAppliedFilterObj;
  }

  function isApplyFilterActionPossible() {
    const keys = fieldOptions.map((item) => item.name);
    const filteredInitFilter = replaceNullValWithEmptyArray(
      Object.entries(lastAppliedFilterstore).filter(([key, val]) =>
        keys.includes(key)
      )
    );

    const filteredCurrFilter = replaceNullValWithEmptyArray(
      Object.entries(filterStore).filter(([key, val]) => keys.includes(key))
    );
    const finalInitFilterObj = Object.fromEntries(filteredInitFilter);
    const finalCurrentFilterObj = Object.fromEntries(filteredCurrFilter);

    //compare
    return !isEqual(finalInitFilterObj, finalCurrentFilterObj);
  }

  const selectedFilterValue = (e, entryName) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      [entryName]: e,
    }));
  };

  const applyFilter = () => {
    updateApplyFilterFlagForCurrentPage();
  };

  const clearFilter = () => {
    const keys = fieldOptions.map((item) => item.name);
    setFilterStore((prevFilterStore) => {
      return Object.fromEntries(
        Object.entries(prevFilterStore).map(([key, val]) => {
          if (keys.includes(key)) {
            return [key, initialFilterStore[key]];
          }
          return [key, val];
        })
      );
    });
    updateApplyFilterFlagForCurrentPage();
  };

  useEffect(() => {
    if (isEmpty(filterStore.Positions)) {
      setFilterStore((prev) => ({ ...prev, Speciality: [] }));
      setIsDisabled(true);
    } else if (dropdownData.SpecialityOption.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore.Positions, dropdownData.SpecialityOption]);

  useEffect(() => {
    setLeftHandFilterParams(() => getLeftHandFilters());
    // eslint-disable-next-line
  }, [lastAppliedFilterstore]);

  return (
    <div className="select-category-block mb-4">
      <div className="card bg-transparent sidebar-height">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-1">
            <div className="d-flex align-items-baseline">
              {language === "en" ? (
                <h4>{t("dashboardText.filter")}</h4>
              ) : (
                <h3>{t("dashboardText.filter")}</h3>
              )}
              <img className="ms-1" src={FilterIcon} alt="filter icon" />
            </div>
            {
              //d-block d-md-none d-xl-block  - To hide minimize text
              language === "en" ? (
                <h6
                  className="card-title label minimize-label ms-0 ms-md-auto ms-xl-0 mb-0"
                  onClick={() => setMinimize(true)}
                >
                  {t("dashboardText.minimize")}
                </h6>
              ) : (
                <div
                  className="card-title label minimize-label ms-0 ms-md-auto ms-xl-0 mb-0"
                  onClick={() => setMinimize(true)}
                  style={{ fontSize: "13px" }}
                >
                  {t("dashboardText.minimize")}
                </div>
              )
            }
          </div>

          <div>
            <div className="search-bar mt-4">
              <div className="search-input">
                <img src={SearchIcon} alt="search" loading="lazy" />
                <input
                  placeholder="Search"
                  name="search"
                  type="text"
                  id="searchtext"
                  className="form-control"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          {fieldOptions?.map((entry, i) => (
            <div className="select-category" key={i}>
              {entry?.type !== "boolean" ? (
                <>
                  <div className="category-block d-flex justify-content-between">
                    <h3>{entry.label}</h3>
                  </div>
                  <div>
                    <SelectUI
                      options={
                        entry?.name === "Speciality"
                          ? selectOptionConverterSpeciality(entry.option)
                          : selectOptionConverter(entry.option)
                      }
                      placeholder={"Choose"}
                      isMulti={
                        multiDisabledNames.includes(entry?.name) ? false : true
                      }
                      isClearable={
                        ClearableSelectionFieldNames.includes(entry?.name)
                          ? true
                          : false
                      }
                      selectedValue={selectedFilterValue}
                      entryName={entry?.name}
                      isDisabled={
                        (entry?.name === "Speciality" && isDisabled) ||
                        disableSidebarFilters
                      }
                      isRenderInputCheckbox={allSelectableFieldNames.includes(
                        entry?.name
                      )}
                      allowSelectAll={allSelectableFieldNames.includes(
                        entry?.name
                      )}
                    />
                  </div>
                </>
              ) : (
                <FormControlLabel
                  control={<Checkbox />}
                  checked={filterStore?.[entry.name] ?? false}
                  onChange={(e) =>
                    selectedFilterValue(e.target.checked ?? null, entry?.name)
                  }
                  label={entry.label}
                  labelPlacement="start"
                />
              )}
            </div>
          ))}
          <div className="d-flex justify-content-center clear-button gap-2">
            <ButtonUI
              disabled={
                !isApplyFilterActionPossible() ||
                isDisableApplyButton ||
                disableSidebarFilters
              }
              title={"Apply"}
              onClick={() => {
                applyFilter();
              }}
            />
            <ButtonUI
              disabled={!isClearFilterActionPossible() || disableSidebarFilters}
              title={"Clear All"}
              onClick={() => {
                clearFilter();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
