import React, { useContext, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./FilterNav.scss";

import { UserContext } from "context/UserContext";
import { FilterContext } from "context/FilterContext";
import { selectOptionConverter } from "helpers/ReusableFunctions";
import {
  lastThreeMonthsEnd,
  lastThreeMonthsStart,
  sortByOptions,
} from "helpers/Constant";

import SelectUI from "components/Select/SelectUI";
import ModalBox from "components/Modal/ModalBox";
import CustomDays from "components/FilterNav/CompareWithFilter/CustomDays";
import CompareWithSelect from "components/FilterNav/CompareWithFilter/CompareWithSelect";
import DateRangeFilter from "components/FilterNav/DateRangeFilter/DateRangeFilter";
import SearchUI from "components/Search/SearchUI";
import ButtonUI from "components/UI/Button/ButtonUI";
import FilterWhiteSvg from "assets/svg/filter-white.svg";
import { useTranslation } from "react-i18next";

const FilterNav = ({
  apiRef,
  showLabels = true,
  ResetComponent = () => <></>,
}) => {
  const options = [
    { value: "value1", label: "Value 1" },
    { value: "value2", label: "Value 2" },
    { value: "value3", label: "Value 3" },
  ];
  const { t } = useTranslation();
  const location = useLocation();
  const { setMinimize, showSidebar, minimize, setSelectedGraph, language } =
    useContext(UserContext);
  const { filterStore, setFilterStore, dropdownData } =
    useContext(FilterContext);

  const [showModal, setShowModal] = useState(false);
  const [hideTopFilters, setHideTopFilters] = useState(false);
  const [custom, setCustom] = useState();
  const [compareRadioSelected, setCompareRadioSelected] = useState(
    filterStore.unit
  );

  const hideCountSelection = [
    "/recentlyactivecandidatesreport",
    "/candidatepipelinereport",
    "/jobs",
  ].includes(location.pathname);

  const disableTopFilters = location.pathname.includes("/leaderboard");

  const [permission, setPermission] = useState({
    month: true,
    sortBy: true,
    saveAs: true,
    more: false,
    search: false,
  });

  const handleShowModal = () => {
    setShowModal((current) => !current);
  };

  // comaprision start date select handler
  const selectedCompareWithValue = (e) => {
    if (e.value === "custom number") {
      setCustom("custom number");
      handleShowModal();
    } else if (e.value === 0) {
      setFilterStore((prev) => ({ ...prev, count: 0 }));
      // setCompareRadioSelected("Quarter");
    } else {
      setFilterStore((prev) => ({
        ...prev,
        count: e.value,
        // unit: compareRadioSelected,
      }));
    }
  };

  const clearTimeComparisonFilter = () => {
    setCompareRadioSelected("quarter");
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      startDate: lastThreeMonthsStart,
      endDate: lastThreeMonthsEnd,
      count: ["/successkpireport", "/timetohirereport"].includes(
        location.pathname
      )
        ? 1
        : 0,
      unit: "quarter",
    }));
    setSelectedGraph("Pie Chart");
  };

  const handleCompareRadioSelectChange = (newUnit) => {
    setCompareRadioSelected(newUnit);
    setFilterStore((prev) => ({
      ...prev,
      unit: newUnit,
    }));
  };

  useLayoutEffect(() => {
    if (["/"].includes(location.pathname)) {
      setPermission({
        month: true,
        sortBy: false,
        search: false,
        saveAs: false,
        more: false,
      });
    }
    if ("/reports" === location.pathname) {
      setPermission({
        month: false,
        sortBy: false,
        search: true,
        saveAs: false,
        more: false,
      });
    }
    if ("/users" === location.pathname) {
      setPermission({
        month: false,
        sortBy: false,
        search: true,
        saveAs: false,
        more: false,
      });
    }
    if (["/permission"].includes(location.pathname)) {
      setPermission({
        month: false,
        sortBy: true,
        search: true,
        saveAs: true,
        more: false,
      });
    }
    if (["/performancereport"].includes(location.pathname)) {
      setPermission({
        month: true,
        sortBy: false,
        search: false,
        saveAs: false,
        more: false,
      });
    }
    if (["/activepipelinereport", "/candidates"].includes(location.pathname)) {
      setPermission({
        month: false,
        sortBy: false,
        search: false,
        saveAs: false,
        more: false,
      });
      setHideTopFilters(true);
    }
    if (
      [
        "/jobs",
        "/candidatepipelinereport",
        "/successkpireport",
        "/timetohirereport",
        "/candidatepipelinereport/leaderboard",
        "/successkpireport/leaderboard",
        "/timetohirereport/leaderboard",
      ].includes(location.pathname)
    ) {
      setPermission({
        month: true,
        sortBy: false,
        search: false,
        saveAs: false,
        more: false,
      });
    }
    if (
      [
        "/recentlyactivecandidatesreport",
        "/recentlyactivecandidatesreport/leaderboard",
      ].includes(location.pathname)
    ) {
      setPermission({
        month: true,
        sortBy: false,
        search: false,
        saveAs: false,
        more: false,
      });
    }
  }, [location, setFilterStore]);

  return (
    <>
      <Row
        className={`align-items-md-center ${
          minimize
            ? "position-relative position-md-static d-flex flex-column flex-md-row"
            : ""
        }`}
      >
        {showSidebar && minimize && (
          <Col className="custom-button col-1 col-md-auto">
            <div
              className="action-button align-self-center mt-0"
              onClick={() => setMinimize(false)}
            >
              <img src={FilterWhiteSvg} alt="Filters" />
              <div
                className="action-text"
                style={
                  language === "en"
                    ? {}
                    : { letterSpacing: "-4px", fontSize: "14px" }
                }
              >
                {t("dashboardText.filter")}
              </div>
            </div>
          </Col>
        )}
        {!hideTopFilters && (
          <>
            {permission.month && (
              <>
                <Col
                  md={4}
                  className={`mt-2 mt-md-0 d-flex gap-3 align-items-center justify-content-start`}
                >
                  {showLabels && <span className="filter_label">Dates</span>}
                  <DateRangeFilter
                    compareRadioSelected={compareRadioSelected}
                    onCompareRadioSelectChange={handleCompareRadioSelectChange}
                    isDisabled={disableTopFilters}
                  />
                </Col>
                {!hideCountSelection && (
                  <Col
                    md={4}
                    className="mt-2 mt-md-0 d-flex gap-3 align-items-center justify-content-start"
                  >
                    {showLabels && (
                      <span className="filter_label">Time Comparison</span>
                    )}
                    <div className="flex-grow-1">
                      <CompareWithSelect
                        compareRadioSelected={compareRadioSelected}
                        onCompareRadioSelectChange={
                          handleCompareRadioSelectChange
                        }
                        selectedCompareWithValue={selectedCompareWithValue}
                        isDisabled={disableTopFilters}
                      />
                    </div>
                  </Col>
                )}

                <Col
                  md={1}
                  className="mt-2 mt-md-0 p-xs-0 m-xs-0 d-flex justify-content-center"
                >
                  <ButtonUI
                    title={"Clear"}
                    onClick={() => clearTimeComparisonFilter()}
                    outline="outline"
                    disabled={disableTopFilters}
                  />
                </Col>
                <Col
                  md={1}
                  className={`mt-2 mt-md-0 p-xs-0 m-xs-0 order-first order-md-0 ms-auto ${
                    !minimize ? "d-flex justify-content-end " : ""
                  }`}
                >
                  <div className={`${minimize ? "custom_div_position" : ""}`}>
                    <ResetComponent />
                  </div>
                </Col>
              </>
            )}
            {permission.sortBy && (
              <Col md={2} className="mt-2">
                <SelectUI
                  options={sortByOptions}
                  placeholder={"Sort By"}
                  isDisabled={disableTopFilters}
                />
              </Col>
            )}
            {permission.search && (
              <Col md={2} className="mt-2">
                <SearchUI apiRef={apiRef} />
              </Col>
            )}
            {location.pathname === "/users" && (
              <>
                <Col md={8} className="mt-2"></Col>
                <Col md={2} className="mt-2">
                  <SelectUI
                    options={selectOptionConverter(
                      dropdownData?.IndustryOption
                    )}
                    placeholder={"Industry"}
                  />
                </Col>
              </>
            )}
            {(location.pathname === "/performancereport" ||
              location.pathname === "/") && <Col md={2} className="mt-2"></Col>}
            {permission.saveAs && (
              <Col md={2} className="mt-2">
                {/* <SelectUI
              options={selectOptionConverter(exportOptions)}
              placeholder={"Save As | Export"}
              selectedValue={getSaveAsExportValue}
            /> */}
              </Col>
            )}
            {permission.more && (
              <Col md={2} className="mt-2">
                <SelectUI options={options} placeholder={"More"} />
              </Col>
            )}
          </>
        )}
      </Row>
      <ModalBox
        show={showModal}
        onHide={handleShowModal}
        title="Filter"
        content={
          custom === "custom number" && (
            <CustomDays
              handleCloseModal={handleShowModal}
              periodType={compareRadioSelected}
            />
          )
        }
        size="md"
      />
    </>
  );
};

export default FilterNav;
