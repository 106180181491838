import React, {  useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Submenu from "components/SubMenu/Submenu";
import Notifications from "components/Settings/Notifications";
import LoginAndSecurity from "components/Settings/LoginAndSecurity";
import Profile from "components/Settings/Profile";

const Settings = () => {
  const location = useLocation();
  const routing = location.state?.routing;

  const [basicActive, setBasicActive] = useState();
  const [renderFlag, setRenderFlag] = useState(Math.random());

  const reRenderComponent = () => {
    setRenderFlag(Math.random());
  };

  const tabs = [
    {
      index: "Profile",
      title: "Basic Profile Info",
      content: <Profile />,
    },
    {
      index: "Settings",
      title: "Login & Security",
      content: (
        <LoginAndSecurity
          renderFlag={renderFlag}
          reRenderComponent={reRenderComponent}
        />
      ),
    },
    {
      index: "Notification",
      title: "Notifications",
      content: <Notifications />,
    },
    // {
    //   index: "Preferences",
    //   title: "Preferences",
    //   content: <Preferences />,
    // },
  ];

  useLayoutEffect(() => {
    if (routing) {
      setBasicActive(tabs.find((val) => val.index === routing).title);
    } else {
      setBasicActive(tabs[0].title);
    }
    // eslint-disable-next-line
  }, [routing]);

  if (!basicActive) {
    return <></>;
  }

  return (
    <div>
      <Submenu
        tabs={tabs}
        basicActive={basicActive}
        setBasicActive={setBasicActive}
      />
    </div>
  );
};

export default Settings;
