import React from "react";
import "./customError.scss";

const CustomError = ({ message = "", icon, className }) => {
  return (
    <div className={`custom-error ${className}`}>
      {icon ? <span className="me-1">{icon}</span> : null}
      {message}
    </div>
  );
};

export default CustomError;
