import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import "../../pages/Users/Users.scss";
import SelectUI from "../Select/SelectUI";
import { selectOptionConverter } from "../../helpers/ReusableFunctions";
import {
  actionOption,
  reportOption,
  userTypeOption,
} from "../../helpers/Constant";

const AddPermissionCard = ({ handleCloseModal, reportData, setReportData }) => {
  const [formData, setFormData] = useState({
    user: userTypeOption[0],
    action: actionOption[0],
    reports: [],
  });
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    const newErrors = {};

    // Perform form validation
    if (!formData.user.trim()) {
      newErrors.user = "Required";
    }
    if (!formData.action.trim()) {
      newErrors.action = "Required";
    }
    if (formData.reports.length === 0) {
      newErrors.reports = "Required";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const newUserPermission = {
      id: reportData.length + 1,
      ...formData,
    };
    setReportData((prevUserData) => [...prevUserData, newUserPermission]);

    // Reset the form
    setFormData({
      user: "",
      action: "",
      reports: [],
    });

    setErrors({});
    handleCloseModal();
  };

  const selectedFormValue = (e) => {
    if (userTypeOption.includes(e.value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        user: e.value,
      }));
      return;
    }
    if (actionOption.includes(e.value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        action: e.value,
      }));
      return;
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        reports: e?.map((val) => val.value),
      }));
    }
  };

  return (
    <Row>
      <Col md={12}>
        <div className="permission-card">
          <form>
            <Row>
              <Col md={12}>
                <div className="user-label">
                  {"Choose User Type "}
                  {errors.user && <span className="error">{errors.user}</span>}
                </div>
                <SelectUI
                  options={selectOptionConverter(userTypeOption)}
                  className="add-class-select"
                  defaultValue={true}
                  selectedValue={selectedFormValue}
                />
              </Col>

              {/* <Col md={6}>
                <div className='user-label'>
                  Action{' '}
                  {errors.action && (
                    <span className='error'>{errors.action}</span>
                  )}
                </div>
                <SelectUI
                  options={selectOptionConverter(actionOption)}
                  className='add-class-select'
                  defaultValue={true}
                  selectedValue={selectedFormValue}
                />
              </Col> */}
              <Col md={12}>
                <div className="user-label">
                  {"Choose Report Type "}
                  {errors.reports && (
                    <span className="error">{errors.reports}</span>
                  )}
                </div>
                <SelectUI
                  options={selectOptionConverter(reportOption)}
                  className="add-class-select"
                  isMulti={true}
                  selectedValue={selectedFormValue}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="user-card-button">
                <div className="action-button mb-4" onClick={handleSubmit}>
                  <div className="action-text">Add</div>
                </div>
                <div
                  className="action-button-outline"
                  onClick={handleCloseModal}
                >
                  <div className="action-text">Cancel</div>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Col>
    </Row>
  );
};

export default AddPermissionCard;
