import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";

import "../ProfileModalContent.scss";
import { createNote, updateNote } from "services/FetchApiData";
import { isEmpty } from "lodash";

const TakeNote = ({
  notes = [],
  setNotes = () => {},
  candidateId = "",
  noteSelected = {},
  setNoteSelected = () => {},
}) => {
  const initialFormData = {
    title: "",
    content: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [isValidForm, setIsValidForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const divRef = useRef(null);

  const validateForm = () => {
    const check = Object.values(formData).every((val) => !!val.trim());
    setIsValidForm(check);
  };

  const handleSaveNotes = async (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      candidate_id: candidateId,
    };

    try {
      setLoading(true);
      if (isEmpty(noteSelected)) {
        const res = await createNote(payload);
        setNotes(() => [
          ...notes,
          {
            ...res.data.data.candidates.save_cm_note,
          },
        ]);
      } else {
        const res = await updateNote(noteSelected?.id, payload);
        const updatedNote = res.data.data.candidates.save_cm_note;

        setNotes((prevNotes) => {
          return prevNotes.map((note) =>
            note.id === updatedNote.id ? updatedNote : note
          );
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setFormData(initialFormData);
      if (!isEmpty(noteSelected)) {
        setNoteSelected({});
      }
    }
  };

  const handleCancel = () => {
    setFormData(initialFormData);
    setNoteSelected({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (!isEmpty(noteSelected)) {
      divRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [noteSelected]);

  useEffect(() => {
    setFormData({
      title: noteSelected?.title ?? "",
      content: noteSelected?.content ?? "",
    });
  }, [noteSelected.content, noteSelected.title]);

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <div id="take-note-div">
      <p className="profile_section-title mb-0">New Note</p>
      <div className="card mt-3 mb-4 px-0" ref={divRef}>
        <div className="card-body">
          <form className="d-flex flex-column gap-2" onSubmit={handleSaveNotes}>
            <div className="shadow-2 rounded-1">
              <input
                type="text"
                name="title"
                className="border-0 rounded-2 overflow-x-hidden take-note-input py-2 px-3 w-100"
                style={{
                  outline: "none",
                }}
                autoFocus={true}
                onChange={(e) => handleInputChange(e)}
                value={formData.title}
                placeholder="Note Name"
              />
            </div>
            <div className="shadow-2 rounded-1">
              <textarea
                rows={3}
                cols={28}
                name="content"
                placeholder="Enter note here..."
                className="border-0 rounded-2 w-100 py-2 px-3  take-note-input"
                style={{
                  outline: "none",
                  minHeight: "55px",
                }}
                onChange={(e) => handleInputChange(e)}
                value={formData.content}
              />
            </div>
            <Row className="user-card-button justify-content-between ms-auto">
              <Col className="col-content col-sm-0 flex-md-grow-1">
                <button
                  disabled={!candidateId || !isValidForm || loading}
                  type="submit"
                  className="action-button px-1 w-100 d-flex justify-content-center"
                >
                  {loading ? (
                    <div>
                      <Spinner
                        animation="border"
                        size="sm"
                        className="text-white"
                        style={{ width: "1rem" }}
                      />
                    </div>
                  ) : (
                    <div className="px-0 flex-grow-1 m-0">
                      {isEmpty(noteSelected) ? "Save" : "Update"}
                    </div>
                  )}
                </button>
              </Col>
              <Col className=" col-content col-sm-0 flex-md-grow-1 ">
                <div
                  className="action-button-outline w-100"
                  onClick={handleCancel}
                >
                  <div className="px-0 flex-grow-1 m-0">Cancel</div>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TakeNote;
