import React, { useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid-pro";

import "./Users.scss";
import Submenu from "components/SubMenu/Submenu";
import FilterNav from "components/FilterNav/FilterNav";
import UserPermissions from "components/Users/UserPermissions";
import ReportPermissions from "components/Users/ReportPermissions";

const Users = () => {
  const userPermissionApiRef = useGridApiRef();
  const reportPermissionApiRef = useGridApiRef();

  const tabs = [
    {
      title: "User Permissions",
      content: <UserPermissions apiRef={userPermissionApiRef} />,
    },
    {
      title: "Report Permissions",
      content: <ReportPermissions apiRef={reportPermissionApiRef} />,
    },
  ];

  const [basicActive, setBasicActive] = useState(tabs[0].title);

  return (
    <>
      <h4>Users</h4>
      <Submenu
        tabs={tabs}
        FilterNav={
          <FilterNav
            apiRef={
              basicActive === "User Permissions"
                ? userPermissionApiRef
                : reportPermissionApiRef
            }
          />
        }
        basicActive={basicActive}
        setBasicActive={setBasicActive}
      />
    </>
  );
};

export default Users;
