import React, { useContext } from "react";
import { DataGridPro, gridClasses } from "@mui/x-data-grid-pro";

import "./PerformanceTableReport.scss";
import { UserContext } from "context/UserContext";
import FilterNav from "components/FilterNav/FilterNav";
import PageTitle from "components/PageTitle/PageTitle";
import { convertGridColumnMenu } from "helpers/ReusableFunctions";
import ExportSvg from "../../assets/svg/exports.svg";
import FilterWhiteSvg from "../../assets/svg/filter-white.svg";
import PeformanaceTableData from "../../mockdata/PeformanaceTableData.json";
import { useTranslation } from "react-i18next";

const PerformanceTableReport = () => {
  const { t } = useTranslation();
  const { language } = useContext(UserContext);

  const columns = [
    {
      field: "id",
      headerName: "id",
      width: 30,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Applicant Name",
      width: 400,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 400,
      sortable: false,
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 400,
      sortable: false,
    },
  ];

  const intialHiddenColumns = {
    id: false,
  };

  const getRowHeight = (params) => {
    return 90; // In pixels
  };

  return (
    <>
      <PageTitle title={t("performanceReportText.lineChartTitle")} />
      <FilterNav />
      <div className="main-card mt-4 p-3">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex gap-4">
            <div className="currentRole">
              <h4>44%</h4>
              <p>Happy in current role</p>
            </div>
            <div className="techStack">
              <h4>12%</h4>
              <p>Dislike tech stack</p>
            </div>
          </div>
          <div className="d-flex">
            <div className="action-button align-self-center">
              <img src={ExportSvg} alt="Export" />
              <div className="action-text">
                {t("performanceReportText.export")}
              </div>
            </div>
            <div className="action-button align-self-center">
              <img src={FilterWhiteSvg} alt="Filters" />
              <div className="action-text">{t("dashboardText.filter")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card p-3 mt-4">
        <div className="cstm-mui-datagrid" style={{ width: "100%" }}>
          <DataGridPro
            rows={PeformanaceTableData}
            columns={columns}
            disableColumnFilter
            disableColumnHeaderSorting
            disableColumnReorder
            disableColumnMenu
            disableChildrenSorting
            disableColumnResize
            disableRowSelectionOnClick
            hideFooter
            initialState={{
              ...columns.initialState,
              columns: {
                ...columns.initialState?.columns,
                columnVisibilityModel: intialHiddenColumns,
              },
            }}
            getRowHeight={getRowHeight}
            sx={{
              [`.${gridClasses.columnSeparator} svg`]: {
                visibility: "hidden",
              },
            }}
            localeText={convertGridColumnMenu(language)}
          />
        </div>
      </div>
    </>
  );
};

export default PerformanceTableReport;
